(function(ns) {
	var BingMap = function(settings) {
		this.merge = function(obj1, obj2) {
			var tObj = {}, key;

			for(key in obj1) {
				if(!obj1.hasOwnProperty(key)) continue;
				tObj[key] = obj1[key];
			}

			for(key in obj2) {
				if(!obj2.hasOwnProperty(key)) continue;
				tObj[key] = obj2[key];
			}

			return tObj;
		};

		this._locationChanged = false;
		this.isInitialized = false;
		this.queryFactory = settings.queryFactory;
		this.culture = settings.culture;
		this.modules = settings.modules || [];
		this.templateRenderer = settings.templateRenderer || [];
		this.mapOptions = this.merge({
			mapTypeId: Microsoft.Maps.MapTypeId.road,
			showMapTypeSelector: false,
			enableClickableLogo: false,
			enableSearchLogo: false,
			showDashboard: true
		}, settings.mapOptions);

		this.focusInitialLocation = function() {
			if(settings.initialLocation) {{
				var _ = this;

				this.query(
					settings.initialLocation.name,
					settings.initialLocation.require,
					function(location) {
						if(location.length == 0) return;
						if(_._locationChanged) return;

						_.navigateTo(location[0].location, location[0].bounds);
					}
				);
			}}
		}
	};

	BingMap.prototype = {
		initialize: function(element, onFinish) {
			var _ = this;
			var moduleLoaders = [];

			for(var i = 0, ii = this.modules.length; i < ii; i++) {
				var module = this.modules[i];

				if(typeof module === "string") {
					module = {
						name: module,
						options: {}
					}
				}

				(function(module) {
					var loader = function(callback) {
						module.options.callback = function() {
							callback();
						};

						Microsoft.Maps.loadModule(module.name, module.options);
					};

					moduleLoaders.push(loader);
				})(module);
			}

			async.parallel(moduleLoaders, function() {
				_.map = new Microsoft.Maps.Map(element, _.mapOptions);
				_.isInitialized = true;
				_.focusInitialLocation();

				onFinish();
			});
		},
		on: function(event, callback) {
			Microsoft.Maps.Events.addHandler(this.map, event, callback);
		},
		query: function(query, requiredLocationType, callback) {
			this.queryFactory(query, function(result) {
				var filteredResult = [];

				for(var i = 0, ii = result.length; i < ii; i++) {
					var item = result[i];

					if(item.bounds && item.bounds.__type && item.bounds.__type == requiredLocationType) {
						filteredResult.push(item);
					}
				}

				callback(filteredResult);
			});
		},
		insertMarker: function(marker, onClick) {
			onClick = onClick || function() {};
			var map = this.map;
			var pin = null;
			if(marker.color){
				pin = new Microsoft.Maps.Pushpin(marker.location, {
					color: marker.color
			});
			} else {
				pin = new Microsoft.Maps.Pushpin(marker.location);
			};

			Microsoft.Maps.Events.addHandler(pin, "click", function(e) {
				onClick.call(map, marker, e);
			});

			this.map.entities.push(pin);

			return this;
		},
		insertMarkers: function(markers, onClick) {
			for(var i = 0, ii = markers.length; i < ii; i++) {
				this.insertMarker(markers[i], onClick);
			}

			var locations = markers.map(function (marker) {
				return new Microsoft.Maps.Location(
					marker.location.latitude,
					marker.location.longitude
				);
			});

			var startLatitude = $("[name='startLatitude']").val();
			var startLongitude = $("[name='startLongitude']").val();

			var endLatitude = $("[name='endLatitude']").val();
			var endLongitude = $("[name='endLongitude']").val();

			var centerAtPoint = false;

			if (startLongitude && startLatitude && endLongitude && endLatitude) {
				var startPoint = new Microsoft.Maps.Location(startLatitude, startLongitude);
				var endPoint = new Microsoft.Maps.Location(endLatitude, endLongitude);
				centerAtPoint = Microsoft.Maps.Location.areEqual(startPoint, endPoint);
			}

			if (centerAtPoint) {
				this.navigateTo(startPoint);
			} else if (locations.length > 1) {
				var bounds = Microsoft.Maps.LocationRect.fromLocations(locations);
				this.navigateTo(null, bounds);
			} else {
				this.navigateTo(locations[0]);
			}
			return this;
		},
		navigateTo: function(location, bounding) {
			this._locationChanged = true;

			var view = {
				center: location
			};

			if(bounding) {
				view.bounds = bounding;
			} else {
				view.zoom = 17;
			}

			this.map.setView(view);

			return this;
		},
		focus: function() {
			this.map.focus();

			return this;
		}
	};

	ns.BingMap = BingMap;
})(de.ittecture.map);
