jQuery(document).ready(function ($) {
	if ($("#actionBoard").length === 0)
		return;

  var listContainer = $(".list-container, .container-list").listContainer({
      closeAnimationSpeed: "fast",
      openAnimationSpeed: "slow",
      closeAnimation: "slideUp",
      openAnimation: "slideDown",
      autoOpenOnSelector: ".error, #customer-no:empty"
  }).data("listContainer");

  function getTextWidth(text, fontSize, fontFace) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    context.font = fontSize + 'px ' + fontFace;
    return context.measureText(text).width;
  }

    /**********************************************
  /* Example of how to use d3 to create scalable
  /* SVG radial progress bars, controllable values
  /* and colours are passed in via data attributes.
  ************************************************/
  var wrapper = document.getElementById('radialProcess');
  var start = 0;
  var infotext = wrapper.dataset.info;
  var all = parseFloat(wrapper.dataset.actions);
  var answered = parseFloat(wrapper.dataset.answered);
  var end = parseFloat((answered/all)*100);

  var colours = {
    fill: '#019774',
    track: '#f7f7f7',
    text: '#019774',
    stroke: '#ddd',
    infotext: '#019774'
  }

  var radius = 60;
  var border = 20;
  var strokeSpacing = 2;
  var endAngle = Math.PI * 2;
  var formatText = d3.format('0f');
  var boxSize = radius * 2 + 5;
  var count = end;
  var progress = start;
  var step = end < start ? -0.01 : 0.01;

  //Define the circle
  var circle = d3.svg.arc()
    .startAngle(0)
    .innerRadius(radius)
    .outerRadius(radius - border);

  //setup SVG wrapper
  var svg = d3.select(wrapper)
    .append('svg')
    .attr('width', boxSize)
    .attr('height', boxSize);

  // ADD Group container
  var g = svg.append('g')
    .attr('transform', 'translate(' + boxSize / 2 + ',' + boxSize / 2 + ')');

  //Setup track
  var track = g.append('g').attr('class', 'radial-progress');
  track.append('path')
    .attr('class', 'radial-progress__background')
    .attr('fill', colours.stroke)
    .attr('stroke', colours.fill)
    .attr('stroke-width', strokeSpacing + 'px')
    .attr('stroke-opacity', '.3')
    .attr('d', circle.endAngle(endAngle));

  //Add colour fill
  var value = track.append('path')
    .attr('class', 'radial-progress__value')
    .attr('fill', colours.fill)
    .attr('stroke', colours.stroke)
    .attr('stroke-width', strokeSpacing + 'px');

  //Add text value
  var numberText = track.append('text')
    .attr('class', 'radial-progress__answered')
    .attr('fill', colours.text)
    .attr('text-anchor', 'start')
    .attr('y', -20)
    .attr('x', (-1*getTextWidth(answered, 22, 'Arial')/2 ))
    .attr('dy', '22px');

  //Add text value
  var infoText = track.append('text')
    .attr('class', 'radial-progress__info')
    .attr('fill', colours.infotext)
    .attr('text-anchor', 'start')
    .attr('y', 3)
    .attr('x', -1*getTextWidth(infotext, 13, 'Arial')/2)
    .attr('dy', '15px');

  function update(progress) {
    //update position of endAngle
    value.attr('d', circle.endAngle(endAngle * progress));
    //update text value
    numberText.text(answered);
    infoText.text(infotext);
  }

  (function iterate() {
    //call update to begin animation
    update(progress);
    if (count > 0) {
      //reduce count till it reaches 0
      count--;
      //increase progress
      progress += step;
      //Control the speed of the fill
      setTimeout(iterate, 10);
    }
  })();
});
