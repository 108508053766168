jQuery.fn.dashboardBrandBarChart = function (options) {
	var $this = $(this);
	var $elem = $this[0];
	var dataset = $this.data("slice");
	var settings = $.extend({
		cssClasses: ["bar-bpc", "bar-mpc", "bar-bpc", "bar-mpc"],
		baseCssClass: "bar-base",
		cssClassFinder: function(i, type) {
			return type === Types.CURRENT ? settings.cssClasses[i] : settings.baseCssClass;
		},
		width: 320,
		height: 260,
		barwidth: 35,
		offset: 10
	}, options);

	var Types = {
			CURRENT: 0,
			HISTORY: 1
	};
	
	var max = d3.max(flatten(dataset));
	var topborder = 40; // top margin for bar
	var barspace = 5; // padding between bars
	var indent = barspace + 4;
	var textTopMargin = 18;
	var textLeftMargin = 7;
	
	// svg
	var svg = d3.select($elem)
				.data(dataset)
				.append("svg")
				.attr("width", settings.width)
				.attr("height", settings.height);

	// bar for every data pair
	var bar = svg.selectAll(".bar")
		.data(function(d, i) { return d; })
		.enter()
		.append("g").attr("class", "bar");
	
	// history bar (order defines layer position)
	addBarTo(bar, Types.HISTORY);
	// current bar (order defines layer position)
	addBarTo(bar, Types.CURRENT);
	
	// x-axis
	addlineTo(
			svg.append("g").attr("transform", "translate(" + 0 + "," + settings.height + ")"),
			settings.width,
			0
	);
	// y-axis
	addlineTo(
			svg.append("g"),
			0,
			settings.height
	);

	function addlineTo(elem, x, y) {
		elem.append("line")
			.attr("stroke", "#000")
			.attr("stroke-width", 1)
			.attr("x1", 0)
			.attr("y1", 0)
			.attr("x2", x)
			.attr("y2", y);
		return elem;
	}
	
	function flatten(data) {
		return data.reduce(function iter(r, a) {
	        if (a === null) {
	            return r;
	        }
	        if (Array.isArray(a)) {
	            return a.reduce(iter, r);
	        }
	        return r.concat(a);
	    }, []);
	}
	
	function barheight(d, i) {
		return max == 0 ? 0 : (settings.height - topborder) * d[i] / max;
	}

	function addBarTo(bar, type) {
		bar.append("rect")
			.attr("class", function(d, i) {
				return settings.cssClassFinder(i, type);
			})
			.attr("x", function(d, i) {
				return settings.offset + ((2 * i + type) * (settings.barwidth + barspace)) - (type * indent);
			})
			.attr("y", function(d) {
	    		return settings.height - barheight(d, type);
	    	})
	    	.attr("width", settings.barwidth)
	    	.attr("height", function (d) {
	    		return barheight(d, type);
	    	});
		
		return addTextTo(
				bar,
				type    	
		);
	}

	function addTextTo(bar, type) {
		return bar.append("text")
				.text(function(d) {
					return d[type];
				})
				.attr("class", "bar-text")
				.attr("x", function(d, i) {
					return settings.offset + ((2 * i + type) * (settings.barwidth + barspace)) - centerTextIndent(d, type) + textLeftMargin;
				})
				.attr("y", function(d) {
					var h = barheight(d, type);
					if (h < textTopMargin + 4) {
						h = h + textTopMargin + 4;
					}
		    		return settings.height - h + textTopMargin;
		    	})
		    	.attr("width", settings.barwidth)
		    	.attr("height", function (d) {
		    		return barheight(d, type);
		    	});
	}
	
	function centerTextIndent(d, type) {
		var data = d[type];
		result = 1;
		if (data > 99) {
			result = 9;
		} else if (data > 9) {
			result = 4;
		}
		return result - (type === Types.CURRENT ? textLeftMargin : 0);
	}
};

jQuery.fn.dashboardChart = function (options) {
	var $this = $(this);
	return fullDonutChart($this[0], $this.data("slice"), options);
};

jQuery.fn.dashboardRingChart = function (options) {
	var settings = $.extend({
		cssClasses: [
		          ["donut-2", "donut-base"],
		          ["donut-1", "donut-base"],
		          ["donut-3", "donut-base"]
		],
	}, options);
	var $this = $(this);
	return fullDonutChart($this[0], $this.data("slice"), settings);
};

function fullDonutChart($elem, dataset, options) {
	var settings = $.extend({
		cssClasses: [
		          ["donut-1", "donut-2", "donut-3"],
		          ["donut-1", "donut-2", "donut-3"],
		          ["donut-1", "donut-2", "donut-3"]
		],
		baseCssClass: "donut-base",
		cssClassFinder: function(d, i, j) {
			return settings.cssClasses[j][i];
		},
		width: 226,
		height: 226,
		circWidth: 33,
		rectWidth: 6
	}, options);

	// delivers layout
	var pie = d3.layout.pie()
			  .sort(null);

	// delivers startAngle, endAngle & value
	var arc = d3.svg.arc();

	var svg = d3.select($elem)
				.append("svg")
				.data([ dataset ])
				.attr("width", settings.width)
				.attr("height", settings.height)
				.append("g")
				.attr("transform", "translate(" + settings.width / 2 + "," + settings.height / 2 + ")");
	
	var arcs = svg.selectAll("g")
			 .data(d3.values(dataset))
			 .enter()
			 .append("g");
				
	arcs.selectAll("path")
		.data(function(d) {
			return pie(d);
		})
		.enter()
		.append("path")
		.attr("class", function(d, i, j) {
			return settings.cssClassFinder(d, i, j);
		})
		.attr("d", function(d, i, j) {
			return arc.innerRadius(83 - settings.circWidth * j).outerRadius((settings.height / 2) - settings.circWidth * j)(d);
		});
	
	svg.append("g").append("rect")
	.attr("x", -settings.rectWidth / 2)
	.attr("y", -settings.height / 2)
	.attr("width", settings.rectWidth)
	.attr("height", settings.height / 2)
	.attr("class", function() {
		return "chart-rect";
	});
};

jQuery.fn.dashboardBrandChart = function(options) {
	var $this = $(this);
	return splitDonutChart($this[0], $this.data("slice"), options);
}

jQuery.fn.dashboardSplitRingChart = function(options) {
	var settings = $.extend({
		cssClasses: [
		          ["donut-2", "donut-2"],
		          ["donut-1", "donut-1"]
		],
	}, options);
	var $this = $(this);
	return splitDonutChart($this[0], $this.data("slice"), settings);
}

function splitDonutChart($elem, dataset, options) {
	var settings = $.extend({
		cssClasses : [
		    [ "donut-2", "donut-1" ],
		    [ "donut-2", "donut-1" ]
		],
		baseCssClass : "donut-base",
		cssClassFinder: function(index, d, i, j) {
			return i == 0 ? settings.cssClasses[index][j] : settings.baseCssClass;
		},
		width : 226,
		height : 226,
		circWidth : 33,
		rectWidth : 6
	}, options);
	
	var pi = Math.PI;
	
	// delivers layout
	var pieL = d3.layout.pie()
				.sort(null)
				.value(function(d) {
					return [d];
				})
				.startAngle(-180 * (pi / 180))
				.endAngle(0);
	var pieR = d3.layout.pie()
				.sort(null)
				.value(function(d, i) {
					return [d];
				})
				.startAngle(180 * (pi / 180))
				.endAngle(0);

	// delivers startAngle, endAngle & value
	var arcL = d3.svg.arc();
	var arcR = d3.svg.arc();

	var svgL = d3.select($elem)
				.append("svg")
				.data([ dataset[0] ])
				.attr("width", settings.width / 2)
				.attr("height", settings.height)
				.append("g")
				.attr("transform", "translate(" + settings.width / 2 + "," + settings.height / 2 + ")");
	var svgR = d3.select($elem)
				.append("svg")
				.data([ dataset[1] ])
				.attr("width", settings.width / 2)
				.attr("height", settings.height)
				.append("g")
				.attr("transform", "translate(0," + settings.height / 2 + ")");

	var arcsL = svgL.selectAll("g")
					.data(d3.values(dataset[0]))
					.enter()
					.append("g");

	var arcsR = svgR.selectAll("g")
					.data(d3.values(dataset[1]))
					.enter()
					.append("g");

	arcsL.selectAll("path")
		.data(function(d) {
			return pieL(d);
		})
		.enter()
		.append("path")
		.attr("class", function(d, i, j) {
			return settings.cssClassFinder(0, d, i, j);
		})
		.attr("d", function(d, i, j) {
			return arcL.innerRadius(83 - settings.circWidth * j).outerRadius((settings.height / 2) - settings.circWidth * j)(d);
		});
	arcsR.selectAll("path")
		.data(function(d) {
			return pieR(d);
		})
		.enter()
		.append("path")
		.attr("class", function(d, i, j) {
			return settings.cssClassFinder(1, d, i, j);
		})
		.attr("d", function(d, i, j) {
			return arcR.innerRadius(83 - settings.circWidth * j).outerRadius((settings.height / 2) - settings.circWidth * j)(d);
		});
	
	svgL.append("g").append("rect")
		.attr("x", -settings.rectWidth / 2)
		.attr("y", -settings.height / 2)
		.attr("width", settings.rectWidth / 2)
		.attr("height", settings.height)
		.attr("class", function() {
			return "chart-rect";
		});
	svgR.append("g").append("rect")
		.attr("x", 0)
		.attr("y", -settings.height / 2)
		.attr("width", settings.rectWidth / 2)
		.attr("height", settings.height)
		.attr("class", function() {
			return "chart-rect";
		});
};