(function ($) {
	$.fn.userMessage = function (options) {
		var $this = this;
		var $userMessage = $("#" + this.attr("data-usermessage-reference"));
		var $userMessageContainer = $userMessage.find(".user-message-container");
		var usersHoversContainer = false;

		$userMessageContainer.hover(function() {
			usersHoversContainer = true;
		}, function() {
			usersHoversContainer = false;
		});

		function showMessage() {
			$userMessage.fadeIn("fast");
		}

		function hideMessage() {
			$userMessage.fadeOut("fast");
		}

		var settings = $.extend({
			actions: {},
			triggers: function(event) {
				return event.originalEvent !== undefined;
			}
		}, options || {});

		this.on("click", function(event) {
			if(settings.triggers(event)) {
				event.preventDefault();
				showMessage();
			}
		});

		$userMessage.on("click", function() {
			if(!usersHoversContainer) {
				if("blur" in settings.actions) {
					settings.actions.blur();
				}

				hideMessage();
			}
		});

		$userMessageContainer.find("[data-usermessage-answer]").on("click", function() {
			var answer = $(this).attr("data-usermessage-answer");

			if(answer in settings.actions) settings.actions[answer]();

			hideMessage();
		});
	}
})(jQuery);
