(function(ns, $) {
	"use strict";

	function get_attr($el, attr, default_value) {
		return $el.is("[" + attr + "]") ? $el.attr(attr) : default_value;
	}

	function get_name($el) {
		return get_attr($el, "name", "");
	}

	function get_id($el) {
		return get_attr($el, "id", "");
	}

	function get_label($el) {
		var id = get_id($el);
		var $label = $("label[for='" + id + "']");

		if($label.length === 0) {
			$label = $el.closest("label").clone().children().remove().end();
		}

		if($label.length === 0 && $el.is("[aria-labelledby]")) {
			$label = $("#" + $el.attr("aria-labelledby")).clone().children().remove().end();
		}

		if($label.length === 0 && $el.is("[title]")) {
			return $.trim($el.attr("title"));
		}

		return $.trim($label.text());
	}

	var Static = function($el) {
		this.el = $el;
		this.id = get_id($el);
		this.label = get_label($el);
		this.custom_id = "static_" + this.id + "_" + (new Date()).getTime();
		this.is_disabled = true;
		this.is_readonly = true;

		Object.defineProperty(this, "value", {
			get: function() {
				return $el.text();
			},
			set: function() {
				console.debug("element is static. setting values is an undefined behaviour.", $el);
			}
		});
	};

	var Option = function($option, select) {
		var option = this;

		this.el = $option;
		this.parent = select;
		this.value = $option.val();
		this.label = $option.text();
		this.last_change = new Date();
		this.lazy = false;
		this.is_filtered = false;

		Object.defineProperty(this, "selected", {
			get: function() {
				if(option.is_selected()) {
					return option.value;
				}
			},
			set: function(value) {
				if(option.lazy && !value) {
					option.destroy();
				}

				option.parent.select_option(option, value);
			}
		});

		this.on = $.fn.on.bind($(this));
		this.dispatch_event = $.fn.trigger.bind($(this));

		this._update = function() {
			setTimeout(function() {
				option.last_change = new Date();
			}, 0);
		};

		this.select = function() {
			this.el.prop("selected", true);
			this._update();
			return this;
		};

		this.is_selected = function() {
			return this.el.prop("selected");
		};

		this.deselect = function() {
			this.el.prop("selected", false);
			this._update();
			return this;
		};

		this.toggle = function() {
			this.is_selected()
				? this.deselect()
				: this.select();

			return this;
		};

		this.destroy = function() {
			this.el.remove();
			this.parent.remove_option(this);
			this.dispatch_event(Option.EVENT_DESTROY);
			return this;
		};
	};

	Option.EVENT_DESTROY = "option.destroy";

	var Input = function($el) {
		this.el = $el;
		this.name = get_name($el);
		this.id = get_id($el);
		this.label = get_label($el);
		this.custom_id = "input_" + this.id + "_" + (new Date()).getTime();
		this.is_disabled = $el.is(":disabled");

		Object.defineProperty(this, "value", {
			configurable: true,
			get: function() {
				return $el.val();
			},
			set: function(value) {
				$el.val(value);
				$el.trigger("change");
			}
		});

		this.pull = function() {
			var value = this.value;
			this.value = "";
			return value;
		};
	};

	Input.prototype = {};

	var Checkbox = function($el) {
		Input.call(this, $el);

		Object.defineProperty(this, "value", {
			get: function() {
				return $el.is(":checked");
			},
			set: function(value) {
				if(value) {
					$el.prop("checked", true);
					$el.attr("checked", "checked");
				} else {
					$el.prop("checked", false);
					$el.removeAttr("checked");
				}

				$el.trigger("change");
			}
		})
	};

	Checkbox.prototype = Input.prototype;

	var Number = function($el) {
		Input.call(this, $el);
		this.step = $el.attr("step");

		if(!this.step || this.step === "any") {
			this.step = 1;
		}
	};

	Number.prototype = Input.prototype;

	Number.prototype.modify = function(value) {
		this.value = this.value + value;
		return this;
	};

	Number.prototype.increase = function(step) {
		step = step || this.step;
		this.modify(step);
	};

	Number.prototype.decrease = function(step) {
		step = step || this.step;
		this.modify(-step);
	};

	var Select = function($el) {
		Input.call(this, $el);

		var select = this;
		this.is_multiple = $el.is("[multiple]");
		this.options = $el.find("option").map(function() {
			return new Option($(this), select);
		}).get();

		Object.defineProperty(this, "empty_option", {
			get: function() {
				if(typeof(select.empty_option_cache) === "undefined") {
					var empty_option = false;
					var options = this.options;

					for(var i = 0, ii = options.length; i < ii; i++) {
						if(options[i].value === "") {
							empty_option = options[i];
							break;
						}
					}

					select.empty_option_cache = empty_option;
				}

				return select.empty_option_cache;
			}
		});

		Object.defineProperty(this, "expand_type", {
			get: function() {
				if(select.is_multiple || (!select.is_multiple && select.empty_option)) {
					return "checkbox";
				}

				return "radio";
			}
		});
	};

	Select.prototype = Input.prototype;

	Select.prototype.get_option_count = function() {
		return this.options.length;
	};

	Select.prototype.remove_option = function(option) {
		var index = this.options.indexOf(option);
		if(index > -1) {
			this.options.splice(index, 1);
			this.el.trigger("change");
		}
		return this;
	};

	Select.prototype.add_option = function(option) {
		option.parent = this;
		this.el.append(option.el);
		this.options.push(option);
		this.el.trigger("change");
		return this;
	};

	Select.prototype.deselect_options = function() {
		this.options.forEach(function(option) {
			option.deselect();
		});
		this.el.trigger("change");
		return this;
	};

	Select.prototype.select_option = function(option, state) {
		if(!this.is_multiple) {
			this.deselect_options();

			if(state) {
				option.select();
			} else {
				if(this.has_empty_option) {
					this.has_empty_option.select();
				}
			}
		} else {
			if(state) {
				option.select();
			} else {
				option.deselect();
			}
		}

		this.el.trigger("change");
		return this;
	};

	var inputs = {
		"select": Select,
		"text": Input,
		"search": Input,
		"number": Number,
		"textarea": Input,
		"option": Option,
		"static": Static,
		"checkbox": Checkbox
	};

	ns.input_interface = {
		types: inputs,
		create: function(element) {
			var $el = $(element);

			if($el.length == 0) return null;

			var type_name = $el.prop("tagName").toLowerCase();

			if(type_name === "input") {
				type_name = get_attr($el, "type", "text");
			}

			if(!(type_name in inputs)) {
				type_name = "static";
			}

			var type = inputs[type_name];

			return new type($el);
		}
	};
})(de.ittecture.utils, jQuery);
