jQuery(document).ready(function ($) {
	if (
		$("#ecoCertificateFulfilledPseudoCheckbox").length === 0
		|| $("#ecoCertificateFulfilled").length === 0
	) {
		return;
	}

	$("#ecoCertificateFulfilledPseudoCheckbox").on("click", function () {
		document
			.getElementById("ecoCertificateFulfilled")
			.toggleAttribute("checked");
		$("#filter-panel").submit();
	});
});
