(function(rivets) {
	var grid_base = 12;
	var grid_mapping = {
		"s": function(size, distribute) {
			return "small-" + (distribute ? grid_base / size : size);
		},
		"m": function(size, distribute) {
			return "medium-" + (distribute ? grid_base / size : size);
		},
		"l": function(size, distribute) {
			return "large-" + (distribute ? grid_base / size : size);
		}
	};

	function add_grid($el, grid_size, distribute) {
		if(!grid_size) return;
		var grid_sizes = {};
		grid_size.split(";").forEach(function(size) {
			var size_params = size.split(":");

			grid_sizes[size_params[0]] = parseInt(size_params[1], 10);
		});

		for(var name in grid_sizes) {
			if(!grid_sizes.hasOwnProperty(name)) continue;
			if(!grid_mapping.hasOwnProperty(name)) {
				console.debug("invalid grid mapping", name);
				continue;
			}
			$el.classList.add(grid_mapping[name](grid_sizes[name], distribute));
			$el.classList.add("rv-grid");
		}

		$el.classList.add("columns");
	}

	rivets.binders["grid"] = function($el, grid_size) {
		add_grid($el, grid_size, false);
	};

	rivets.binders["grid-distributed"] = function($el, grid_size) {
		add_grid($el, grid_size, true);
	}
})(rivets);
