(function ($) {
	$.fn.switchPanel = function (options) {
		var settings = $.extend({
			labelOff: "Off",
			labelOn: "On",
			itemClass: ""
		}, options);

		$(this).each( function() {
			var select = $(this),
				switchClasses = (select.is("[data-switch-classes]") ? select.attr("data-switch-classes") : "switch"),
				name = select.attr("name"),
				replacement = $("<ul class='switch-list' data-switch-id='" + select.attr("id") + "' />");

			$(document).on("replace", "select#" + select.attr("id"), function() {
				$("[data-switch-id='" + $(this).attr("id") + "']").remove();
				$(document).off("replace");
				$(this).switchPanel(settings);
			});

			select.find("option").each(function(index) {
				var option = $(this),
					isOn = option.is(":selected"),
					switchName = name + "_" + index,
					switchOffId = switchName + "_off",
					switchOnId = switchName + "_on",
					item = $("<li class='" + settings.itemClass + "' />"),
					switchControl = $("<div class='" + switchClasses + "' />");

				switchControl
					.append("<input id='" + switchOffId + "' name='" + switchName + "' type='radio' data-switch-id='" + index +"' " + (isOn ? "" : "checked") + " />")
					.append("<label for='" + switchOffId + "'>" + settings.labelOff + "</label>")
					.append("<input id='" + switchOnId + "' name='" + switchName + "' type='radio' data-switch-id='" + index +"' " + (isOn ? "checked" : "") + " />")
					.append("<label for='" + switchOnId + "'>" + settings.labelOn + "</label>")
					.append("<span />")
				;

				item
					.append(switchControl)
					.append("<span>" + option.text() + "</span>")
				;

				replacement.append(item);
			});

			replacement.on("change", ".switch input", function(event) {
				var optionIndex = $(this).attr("data-switch-id"),
					isEnabled = /.*_off$/.test($(this).attr("id"));

				$(select
					.find("option")
					.get(optionIndex))
					.prop("selected", !isEnabled)
				;

				select.trigger("change");
			});

			select
				.hide()
				.after(replacement)
			;

			select.closest("form").on("submit", function() {
				replacement
					.find("input")
					.removeAttr("name")
				;
			});
		});
	};
})(jQuery);
