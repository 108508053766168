(function ($) {
	var ListContainer = function (element, options) {

		var $element = $(element);
		var listContainer = this;
		var settings = $.extend({
			autoOpenOnClass: false
		}, options || {});

		this.createDomId = function(id) {
			return id.substr(0, 1) === "#" ? id : "#" + id;
		};

		this.createContainerId = function(id) {
			return id.substr(0, 1) === "#" ? id.substr(1) : id;
		};

		this.forcesSingleContainer = function () {
			return $element.is("[data-list-container-behaviour='force-single']");
		};

		this.isContainerOpened = function (id) {
			return this.getContainer(id).is("[data-list-container-open]");
		};

		this.openContainer = function (id, callback) {

			callback = callback || function () {};

			var $container = this.getContainer(id);

			if($container.closest("form").attr("data-no-toggle")){ return; }

			var skipTo = function() {
				$("html, body").scrollTop($container.offset().top);
			};
			var open = function () {
				$container
					.attr("data-list-container-open", true)
					.children("[data-list-container-section='content']");
			};

			if(listContainer.isContainerOpened(id)) {
				callback(skipTo);
				return;
			}



			if(listContainer.forcesSingleContainer()) {
				listContainer.closeContainers(open);
			} else {
				open();
			}
		};

		this.closeContainer = function (id, callback) {

			callback = callback || function () {};

			if(!listContainer.isContainerOpened(id)) return callback();
			$(this.createDomId(id))
				.find("[data-list-container-open]").removeAttr("data-list-container-open");

			$(this.createDomId(id))
				.removeAttr("data-list-container-open")

		};

		this.closeContainers = function (callback) {
			callback = callback || function () {};

			if(!$element.children("[data-list-container-item][data-list-container-open]").length) return callback();

			$.when(
				$element
					.find("[data-list-container-item]")
					.removeAttr("data-list-container-open")
					.find("[data-list-container-section='content']")
			).done(callback);
		};

		this.getContainer = function (id) {
			return $(this.createDomId(id));
		};

		$element.on("click", "[data-list-container-section='header']", function (event) {

			if ($(event.target).is(":input")) return;
			if ($(event.target).is("a.btn-rep-edit")) return;
			if ($(event.target).is("a.toggle")) return;
			if ($(this).closest("form").attr("data-no-toggle")) return;

			var id = $(this).closest("[data-list-container-item]").attr("id");

			if(!$element.children('#'+id).length) return;

			if(listContainer.isContainerOpened(id)) {
				return listContainer.closeContainer(id);
			} else {
				return listContainer.openContainer(id);
			}
		});
	};

	$.fn.listContainer = function (options) {
		return this.each(function () {
			if($(this).data("listContainer")) return;

			var listContainer = new ListContainer(this, options);
			$(this).data("listContainer", listContainer);
		});
	};
})(jQuery);
