(function(ns) {
	var NamespaceRegistrar = function(root, namespace) {
		this.root = root;
		this.namespace = namespace;

		this._getFQNS = function(key) {
			return this.namespace + "." + key;
		}
	};

	NamespaceRegistrar.prototype = {
		register: function(ns, value) {
			var ref = this.root;
			var fqns = this._getFQNS(ns);
			var fqnsParts = fqns.split(".");

			for(var i = 0, ii = fqnsParts.length - 1; i < ii; i++) {
				var key = fqnsParts[i];

				// ref.hasOwnProperty does not work because ref could be
				// window which is not supported in IE8
				if(!Object.prototype.hasOwnProperty.call(ref, key)) {
					ref[key] = {};
				}

				ref = ref[key];
			}

			ref[fqnsParts[fqnsParts.length - 1]] = value;
		}
	};

	ns.NamespaceRegistrar = NamespaceRegistrar;
})(de.ittecture.utils);