(function($) {
  $(document)
    .ready(
      function() {
        if ($("#recommendation_section").length === 0)
          return;

        var listContainer = $(".list-container, .container-list")
          .listContainer({
            closeAnimationSpeed : "fast",
            openAnimationSpeed : "slow",
            closeAnimation : "slideUp",
            openAnimation : "slideDown",
            autoOpenOnSelector : ".error, #customer-no:empty"
          }).data("listContainer");

        $(this).docketChart();
        
        $("[data-pony-prototype]").pony();
      });
})(jQuery);