(function ($) {
	$.fn.datebadge = function () {
		$(this).each(function () {
			var $this = $(this);
			var dateContainer = $this.text().split("#");
			if (dateContainer.length > 1) {
				var month = dateContainer[0].split("-")[0];
				var year = dateContainer[0].split("-")[1];
				var fullDate = dateContainer[1];
				var template = Handlebars.compile("<div class=\"datebadge\" title=\"{{ dateTitle }}\"><div class=\"datebadge-year\">{{ year }}</div><div class=\"datebadge-month\">{{ month }}</div></div>");
				$this.replaceWith(template({
					year: month,
					month: year,
					dateTitle: fullDate
				}));
			}
		});
	}
})(jQuery);
