jQuery(document).ready(function ($) {
	if ($("#classifications").length === 0)
		return;
	
	$('.table-edit select[multiple]').each(function() {
		var options = {
			filter : true
		};
		var copyAttr = function(name, $source, target) {
			var value = $source.attr("data-".concat(name));
			if (value !== undefined) {
				target[name] = value;
			}
		};
		// These attributes have been taken from multiple.select.js default options
		copyAttr('selectAllText', $(this), options);
		copyAttr('allSelected', $(this), options);
		copyAttr('countSelected', $(this), options);
		copyAttr('noneSelected', $(this), options);
		options['selectType'] = true;
		
		$(this).multipleSelect(
			options
		);
	});
});