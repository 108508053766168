app.on_page("question-authoring").then(function() {
	var $ = jQuery;
	var $page = $('[data-page="question-authoring"]');
	var $submit = $('[data-question-editor="submit"]');
	var context = { enabled: true, handles: function(name) {
		$("[data-handled='" + name + "']").hide();
	} };
	var view = rivets.bind($page, context);

	$submit.on("click", function() {
		context.enabled = false;
		view.unbind();
	});
});
