(function($) {
	$(document).ready(function() {
		if(
				$("#questionnaire-translation").length === 0
				&& $("#questionnaire-selection").length === 0
		) {
			return;
		}
		
		var $interviewContainer = $(".container-list");
		var listContainer = $interviewContainer.listContainer({
			openAnimationSpeed: 0,
			closeAnimationSpeed: 0,
			autoOpenOnSelector: ".error"
		}).data("listContainer");

		var abortButton = $("[name='goBackToOverview']");
		if (abortButton) {
			abortButton.userMessage({
				actions: {
					"yes": function () {
						window.location.href = abortButton.attr('href');
					}
				}
			});
		}

		if($("#questionnaire-translation").length === 0){
			return;
		}

		var editButtons = document.querySelectorAll("a.entity-action-trigger.btn-rep.btn-rep-edit");
		if(editButtons != null && editButtons.length > 0){
			return;
		}

		var items = document.querySelectorAll("li.container-list-item")
		for(var i=0; i < items.length; i++) {
			var item = items[i];
			console.log(item);
			items[i].setAttribute("data-list-container-open", "true")
		}
	});
})(jQuery);
