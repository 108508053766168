jQuery(document).ready(function ($) {
	
	$(".keep-in-sight").keepInSight();
	$(".content").css("padding-bottom", $(window).height() + 150);
	$("input, textarea").placeholder();

	$(document).on("click", "[data-dropdown-toggle]", function(event) {
		event.preventDefault();

		$(this).toggleClass("nav-dropdown-active");
		$($(this).attr("data-dropdown-toggle")).toggleClass("nav-dropdown-active");
	});

	$(".button.transit").on("click", function() {
		$(this).addClass("in-transit");
	});

	$(".switches").each(function(){
		var $this = $(this);

		$this.switchPanel({
			labelOff: $this.is("[data-switch-label-off]") ? $this.attr("data-switch-label-off") : "Off",
			labelOn: $this.is("[data-switch-label-on]") ? $this.attr("data-switch-label-on") : "On",
			itemClass: $this.is("[data-switch-item-class]") ? $this.attr("data-switch-item-class") : ""
		});
	});

	$("#bodyshops").on("click", "tr.bodyshop-selector td:not(.func)", function() {
		var parent = $(this).parent();
		if (parent.is(".active")) {
			parent.removeClass("active");
		} else {
			$("tr.bodyshop-selector").removeClass("active");
			parent.addClass("active");
		}
	});

		$('input[type=date]').each(function(index, element) {
			var $displayField = $(element);
			if (Modernizr.inputtypes.date) {
				$displayField.focus();
				$displayField.blur();
				$(window).scrollTop(0);
			} else {

				var serverFormat = "yy-mm-dd"; // HTML5 Standard
				var options = {};

				/* Let's find the localization to use */
				var locale = $displayField.attr("data-locale") || window.navigator.language;
				if (locale !== undefined) {
					locale = locale.replace("_", "-");
					var $regional = $.datepicker.regional[locale];
					if ($.isEmptyObject($regional)) {
						var lang_country = locale.split("-");
						if (lang_country[1] !== undefined) {
							$regional = $.datepicker.regional[lang_country[0]];
						}
					}
					if (!$.isEmptyObject($regional)) {
						options = $.extend(options, $regional);
					}
				}
				/* Guarantee english as default */
				$.datepicker.setDefaults(
						$.datepicker.regional["en"]
				);

				/* Found http://stackoverflow.com/questions/11522784/how-to-make-jqueryui-datepicker-submit-in-a-different-format-than-what-is-being */
		        /* Create a hidden clone, which will contain the actual HTML5 date value */
		        var $valueField = $displayField.clone(true, true);
		        $valueField.insertAfter($displayField);
		        $valueField.hide();

		        /* Rename the original field, used to contain the display value */
		        $displayField.attr('id', $displayField.attr('id') + '-display');
		        $displayField.attr('name', null); // prevent from being submitted
		        $displayField.attr('type', 'text');

		        /* Create the datepicker with the desired display format and alt field */
		        /* Attention: We use an internal API method. This is uncommon. But currently this is the simplest way to reset the choosen date to null */
		        options = $.extend(
		        		options,
		        		{
		        			altField: "#" + $valueField.attr("id"),
		        			altFormat: serverFormat,
		        			onSelect : function(){
		        		        $displayField.closest('form').submit();
		        		    },
		        			showButtonPanel : true,
		        			closeText: 'X',
		        			beforeShow: function( input ) {
		        				setTimeout(function() {
			        				var clearButton = $(input)
			        					.datepicker("widget")
			        					.find(".ui-datepicker-close");
			        				clearButton.unbind("click").bind("click", function() {
			        					$.datepicker._clearDate(input); // attention: see above
			        				});
		        				}, 1);
		        			}
		        		}
		        );
				$displayField.datepicker(
					options
				);

		        /* Finally, parse the value and change it to the display format */
		        if ($displayField.attr('value')) {
		            var date = $.datepicker.parseDate(serverFormat, $displayField.attr('value'));
		            $displayField.attr('value', $.datepicker.formatDate(options["dateFormat"], date));
		        }
			}
		});

	$(".date-selector").datebadge();

	$(".image-showcase-row").imageShowcaseRow();

	$(".table.table-bodies-toggable .table-group-header").on("click", function() {
		var $this = $(this);

		if($("html").hasClass("lt-ie9")) {
			if($this.hasClass("ie-active")) {
				$this.siblings().css("display", "none");
			} else {
				$this.siblings().css("display", "table-row");
			}

			$this.toggleClass("ie-active");
		} else {
			$this.toggleClass("active");
		}
	});

	// detect firefox versions below 4.0 to apply custom css
	if(/rv:1\.9.*Gecko/.test(navigator.userAgent)) {
		$("html").addClass("lt-fx4");
	}

	// wrap all tables containing soft dividers, as fx prior to version 4
	// doesn’t seem to handle position: relative on tables
	$(".lt-fx4 .table .divider-soft")
		.closest("table")
		.wrap("<div style='position: relative'>")
	;

	if($("#chart-data").length > 0) {

		/**
		 * Drop-in compatibility fix for semi-transparent strokes and fills for old WebKit
		 * browsers as well as Batik export servers. The fix splits rgba fill colors into
		 * solid colors for fills, and a separate fill-opacity attribute.
		 */
		Highcharts.SVGElement.prototype.fillSetter = Highcharts.SVGElement.prototype.strokeSetter = function (value, key, element) {
		    var colorObject;

		    if (typeof value === 'string') {
		        if (value.indexOf('rgba') === 0) {
		            // Split it up
		            colorObject = Highcharts.Color(value);
		            element.setAttribute(key + '-opacity', colorObject.get('a'));
		            element.setAttribute(key, colorObject.get('rgb'));
		        } else if (value == 'transparent') {
		        	element.removeAttribute(key + '-opacity');
		        	element.setAttribute(key + '-opacity', 0);
		        } else {
		            element.removeAttribute(key + '-opacity');
		            element.setAttribute(key, value);
		        }
		    } else {
		        this.colorGradient(value, key, element);
		    }
		};

		var dataContainer = $("#chart-data");
		var chartContainer = $("#chart-container");
		var data = $.parseJSON(dataContainer.text());
		var styles = cdi.getChartStyles();
		var generator = new de.ittecture.chart.generator[dataContainer.attr("data-chart-generator")](data, styles);
		var args = generator.generateRenderOptions();
		args.exporting = $.extend({
			chartOptions: {
				chart: {
					backgroundColor: "white"
				}

			},
			buttons: {
				contextButton: {
					enabled: false
				}
			}
		}, $.parseJSON(dataContainer.attr("data-chart-export-configuration")));

		chartContainer.highcharts(args, function(chart) {
			if(typeof generator.onComplete === "function") {
				generator.onComplete.call(generator, chart);
			}
		});
	}

	$(".filter select:not([multiple])").chosen({
		allow_single_deselect: true,
		width: "100%"
	});

	$(".filter select[multiple]").selectExpander();

	$(".filter-panel").filterPanel();

	$("#benchmark-rating-legend").stickTo($(".benchmark-bubble, .benchmark-circle"));

	if($(".filter-switch").length) {
		var $headers = $(".filter-headers").children();
		var $filterResultRows = $(".filter-result-row");

		$("a.filter-switch").each(function() {
			var $switch = $(this);
			var index = $switch.closest(".filter-container").index();
			var isEnabled = $switch.hasClass("filter-on");

			$($headers[index]).prepend($switch);

			if(!isEnabled) {
				$filterResultRows.each(function() {
					$($(this).children()[index]).html("");
				});
			}
		});
	}

	(function() {
		var focusedFeedback = false;
		var $feedback = $(".feedback-panel");

		$feedback
			.on("message", function() {
				var $badge = $("#feedback-message-count");
				var $icon = $("#feedback-message-icon");
				var messageCount = $(this).feedbackPanel("getMessageCount");

				$badge.text(messageCount === 0 ? "" : messageCount);

				if(messageCount > 0) {
					$(this).feedbackPanel("show");
					$icon.removeClass("ring");
					setTimeout(function() {
						$icon.addClass("ring");
					}, 50);
				}
			})
			.on("mouseenter", function() {
				focusedFeedback = true;
			})
			.on("mouseleave", function() {
				focusedFeedback = false;
			})
			.feedbackPanel()
		;

		$("#feedback-trigger")
			.on("click", function(event) {
				event.preventDefault();
				$feedback.feedbackPanel("toggleVisibility");
			})
			.on("mouseenter", function() {
				focusedFeedback = true;
			})
			.on("mouseleave", function() {
				focusedFeedback = false;
			})
		;

		$(document).on("click", function() {
			if(!focusedFeedback) {
				$feedback.feedbackPanel("hide");
			}
		});
	})();

	$(".typeahead:not(:disabled)").each(function() {
		var $typeahead = $(this);
		var id = $typeahead.attr("id");
		var $typeaheadLocalData = $("[data-typeahead-localdata-for='" + id + "']");
		var $typeaheadSelection = $("[data-typeahead-onchange-for='" + id + "']");
		var $typeaheadLimit = $typeahead.attr("data-typeahead-limit");

		var bloodHoundOptions = {
			queryTokenizer: Bloodhound.tokenizers.whitespace,
			datumTokenizer: Bloodhound.tokenizers.obj.whitespace("name"),
			limit: $typeaheadLimit
		};

		var typeaheadOptions = {
			hint: true
		};

		if($typeaheadLocalData.length) {
			bloodHoundOptions.local = $.parseJSON($typeaheadLocalData.html());
		}

		if($typeahead.is("[data-typeahead-remote-url]")) {
			bloodHoundOptions.remote = {
				url: decodeURIComponent($typeahead.attr("data-typeahead-remote-url"))
			};
		}

		if($typeahead.is("[data-typeahead-prefetch-url]")) {
			typeaheadOptions.prefetch = {
				url: decodeURIComponent($typeahead.attr("data-typeahead-prefetch-url"))
			};
		}

		var engine = new Bloodhound(bloodHoundOptions);

		engine.clearPrefetchCache();
		engine.clearRemoteCache();
		engine.initialize();

		$typeahead.typeahead(typeaheadOptions, {
			name: id,
			displayKey: "name",
			source: engine.ttAdapter(),
			templates: {
				suggestion: function(datum) {
					return datum.name;
				}
			}
		}).on("typeahead:selected typeahead:autocompleted", function(event, datum) {
			$typeaheadSelection
				.val(datum.id)
				.trigger("change")
			;
		});
	});

	(function () {

		function utf8_to_b64(str) {
			return window.btoa(unescape(encodeURIComponent(str)));
		}

		function b64_to_utf8(str) {
			return decodeURIComponent(escape(window.atob(str)));
		}

		var inputField = $(".base64field");
		if (inputField.val()) {
			inputField.val(b64_to_utf8(inputField.val()));
		}

		var submitButton = $(".base64submit");
		submitButton.click(function () {
			var sqlQuery = inputField.val();
			var base64Query = utf8_to_b64(sqlQuery);
			inputField.val(base64Query);
		});
	})();

	var allSelected = false;
	$(".select-all").click(function(){
		$(".bodyshop-selector input[type=checkbox]").prop('checked', allSelected ? false : true);
		allSelected = !allSelected;
	});

	prepareThrobberButtons();
});

function prepareThrobberButtons() {

	prepareThrobberButtonsWithOutClickEvent();

	var throbbers = document.querySelectorAll('[throbber-button]');
	for(var i=0; i < throbbers.length ;i++) {
		var hint = throbbers[i].getAttribute('throbber-message');
		throbbers[i].addEventListener("click", function () {
			document.activateThrobber(hint);
		});
	}
}

function prepareThrobberButtonsWithOutClickEvent() {
	
	document.activateThrobber = function(hint) {
		var loader = document.getElementById("throbber");

		if (typeof hint !== "undefined") {
			var hintContainer = document.querySelector("#throbber>span");
			if (hintContainer !== null) {
				hintContainer.innerHTML = hint;
			}
		}

		if (loader !== null) {
			loader.setAttribute("style", "display:block");
		}
	};

	document.deactivateThrobber = function() {
		var loader = document.getElementById("throbber");
		if (loader !== null) {
			loader.setAttribute("style", "display:none");
		}
	};
}

function manuallyActivateThrobber() {
	var throbbers = document.querySelectorAll('[throbber-button]');
	for(var i=0; i < throbbers.length ;i++){
		var hint = throbbers[i].getAttribute('throbber-message');
		document.activateThrobber(hint);
	}
}

Application = function() {
	this.on = jQuery.fn.on.bind(jQuery(this));
	this.dispatch_event = jQuery.fn.trigger.bind(jQuery(this));

	this.resolve_page = function() {
		var page;

		try {
			page = $("[data-page]").attr("data-page");
		} catch(e) {
			page = null;
		}

		this.dispatch_event(Application.EVENT_PAGE_RESOLVED, [page]);
	};

	jQuery(document).ready(this.resolve_page.bind(this));
};

Application.EVENT_PAGE_RESOLVED = "application:resolved_page";

Application.prototype.on_page = function(page_name) {
	var app = this;

	var promise = new Promise(function(resolve, reject) {
		app.on(Application.EVENT_PAGE_RESOLVED, function(event, page) {
			if(page === page_name) {
				resolve();
			} else {
				reject();
			}
		})
	});

	var then = promise.then;
	var empty_fn = function() {};
	promise.then = function(resolve, reject) {
		return then.call(promise, resolve || empty_fn, reject || empty_fn);
	};

	return promise;
};

Application.prototype.getChartStyles = function() {
	var chartStyles = {};

	for(var i = 0; i < document.styleSheets.length; i++) {
		if(/chart\.css$/.test(document.styleSheets.item(i).href)) {
			var stylesheet = document.styleSheets.item(i);
			var rules = stylesheet.cssRules || stylesheet.rules;

			for(var j = 0; j < rules.length; j++) {
				var style = rules[j].style;

				chartStyles[rules[j].selectorText.substr(1)] = {
					color: style.color,
					backgroundColor: style.backgroundColor,
					borderColor: style.borderColor
				};
			}
		}
	}

	return chartStyles;
};

app = cdi = new Application();
