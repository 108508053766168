(function ($) {
	$.fn.broadSelect = function (options) {
		var settings = $.extend({
			badgeStyle: "badge"
		}, options);

		$(this).each(function() {
			var select = $(this);
			var replacement = $("<div class='broad-select'></div>");
			var choiceList = $("<ol class='broad-list'></ol>");

			select.hide();

			select.find("option").each(function () {
				if(!$(this).val()) return;

				var item = $("<li />");

				item.append("<div class='indicator'><span class='" + settings.badgeStyle + "'>" + $(this).index() + "</span></div>");
				item.append("<div class='description'>" + $(this).text() + "</div>");
				item.attr("data-broad-original-value", $(this).val());

				choiceList.append(item);
			});

			replacement.append(choiceList);
			replacement.attr("class", select.attr("class"));
			select.after(replacement);

			replacement.on("click", ".broad-list li", function (event) {
				event.preventDefault();

				var $this = $(this);
				var $siblings = $this.siblings();

				if (replacement.hasClass("broad-selected")) {
					select
						.val("")
						.trigger("change");

					replacement.removeClass("broad-selected");
					$siblings.removeClass("active");

					if(!Modernizr.csstransitions) {
						$siblings.show();
					}
				} else {
					select
						.val($this.attr("data-broad-original-value"))
						.trigger("change");

					if(!event.isTrigger && Modernizr.csstransitions) {
						$siblings.css("max-height", function() { return $(this).outerHeight() })
					}

					$this.addClass("active");
					$siblings.removeClass("active");
					replacement.addClass("broad-selected");

					if(!Modernizr.csstransitions) {
						$siblings.hide();
					}
				}
			});

			replacement.on("keyup", ".broad-list", function (event) {
				if (event.keyCode !== 38 && event.keyCode !== 40) return;

				var parent = $(this);
				var index;

				if (parent.is(":first-child") && event.keyCode === 38) {
					index = choiceList.children().index();
				} else if (parent.is(":last-child") && event.keyCode === 40) {
					index = 0;
				} else {
					index = (event.keyCode === 38) ? (parent.index() - 1) : (parent.index() + 1);
				}

				$(choiceList.children()[index]).find("a").focus();
			});

			(function(selectedItem) {
				if(selectedItem.length) selectedItem.trigger("click");
			})(replacement.find("li[data-broad-original-value='" + select.find("option[selected]").attr("value") + "']"));
		});
	};
})(jQuery);
