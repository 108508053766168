(function($) {
	var IdentityControlPanel = function($panel, options) {
		var panel = this;

		this.panel = $panel;
		this.form = $panel.is("[data-identity-control-panel-form]") ? $($panel.attr("data-identity-control-panel-form")) : $panel.closest("form");
		this.form.on("submit", function(event) {
			var $form = $(this);
			$form.find(":checkbox").prop("disabled", true);
		});
		this.submitType = $panel.is("[data-submit-type]") ? $panel.attr("data-submit-type") : "redirect";

		if(panel.submitType === "redirect") {
			// it’s necessary to store the current values of multi-choice selects
			// as we have to determine the newly selected or unselected value
			// when a change event is fired
			$panel.find("select[multiple]").each(function() {
				$(this).data("identity-value", $(this).val());
			});
		}

		$panel
			.on("replace", "select", function() {
				$(this).chosen({
					allow_single_deselect: true,
					width: "100%"
				});
			})
			.on("change", "select", function() {
				var $select = $(this);

				if(panel.submitType === "redirect") {
					var redirectTarget;

					// multi-choice selects need extra logic to retrieve the
					// newly selected or deselected value
					if(false) {//$select.is("[multiple]")) {
						var currentValue = $select.val() || [];
						var originalValue = $select.data("identity-value") || [];

						// depending on the length of the current value we can
						// determine if a new selection was made or if one was removed
						if(currentValue.length > originalValue.length) {
							redirectTarget = $(currentValue).not(originalValue).get()[0];
						} else {
							redirectTarget = $(originalValue).not(currentValue).get()[0];
						}
					} else {
						var params={};
						if (!$select.data().hasOwnProperty("destroyer")) {
							window.location.search
								.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(str,key,value) {
									params[key] = value;
								});
						}
						var name = $select.attr("name");
						var value = $select.val();
						if (value) {
							params[name] = value;
						} else {
							delete params[name];
						}
						redirectTarget = location.origin + location.pathname + "?" + $.param(params);
					}

					location.href = redirectTarget;
				} else {
					panel.form.submit();
				}
			})
            .on("keydown", function(e) {
                    if(e.keyCode === 13) {
                            panel.form.submit();
                    }
            })
			.find("input").each(function() {
				var $this = $(this);
				$this.data("stored-value", $this.val());
			}).end();
	};

	IdentityControlPanel.prototype = {

	};

	(function() {
		$.fn.identityControlPanel = function(method) {
			if(IdentityControlPanel.prototype.hasOwnProperty(method)) {
				return IdentityControlPanel.prototype[method].apply(this.data("identityControlPanel"), [].slice.call(arguments, 1));
			} else {
				return this.each(function() {
					var $panel = $(this);
					var options = options || {};

					var panel = new IdentityControlPanel($panel, options);
					$panel.data("identityControlPanel", panel);

					return $panel;
				});
			}
		};
	})();
})(window.jQuery);