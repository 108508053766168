(function(component_name) {
	var Translator = function(translations) {
		this.translations = translations;
	};

	Translator.prototype = {
		trans: function(key) {
			if(this.translations.hasOwnProperty(key)) {
				return this.translations[key];
			}

			return key;
		}
	};

	var ShowFor = function(el, data) {
		var self = this;

		this.el = el;
		this.source = data.source;
		this.translator = new Translator({
			"NONE": "Always enabled (disable Yes/No fields)",
			"YES": "Yes is selected",
			"NO": "No is selected"
		});
	};

	ShowFor.prototype = {
	};

	rivets.components[component_name] = {
		template: function() {
			var template = "<app-form-expanded-select source=\"source\" translator=\"translator\" type=\"radio\" inline=\"false\"></app-form-expanded-select>";
			return template;
		},
		initialize: function(el, data) {
			return new ShowFor(el, data);
		}
	};
})("app-authoring-show-for");
