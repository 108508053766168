(function($) {
	var supportsFileReader = !!(window.File && window.FileList && window.FileReader);

	var ImageDetailView = function(view, placeholder) {
		var _self = this;

		this.view = $(view);
		this.selector = null;
		this.image = this.view.find("img");

		this.close = function() {
			this.view.hide();
		};

		this.removeImage = function() {
			this.selector = null;
			this.image.attr("src", placeholder);
			this.image.attr("alt", "");
			this.close();
		};

		this.view.on("click", ".image-actions-close", function() {
			_self.removeImage();
		});

		this.view.on("click", ".image-actions-remove", function() {
			_self.selector.removeImage();
			_self.removeImage();
		});
	};

	ImageDetailView.prototype = {
		showImage: function(source, description, selector) {
			this.selector = selector;
			this.image.attr("src", source);
			this.image.attr("alt", description);
			this.view.find(".image-actions-remove").css("visibility", selector.isSelectionRemovable() ? "visible" : "hidden");
			this.view.show();
		}
	};

	var ImageSelector = function(selector, detailView, placeholder) {
		var _self = this;

		this.selector = $(selector);
		this.placeholder = placeholder;
		this.detailView = detailView;
		this.uploader = this.selector.find("input[type='file']");
		this.deleter = this.selector.find("input[type='checkbox']");
		this.imagePreview = this.selector.find(".image-ref");
		this.imageName = this.uploader.attr("data-image-reference");

		this.selectFile = function(file) {
			var reader = new FileReader();

			reader.onload = function(event) {
				_self.imagePreview
					.attr("src", event.target.result)
					.removeAttr("data-large")
				;
			};

			reader.readAsDataURL(file);
		};

		this.showDetailView = function() {
			var source = this.imagePreview.is("[data-large]") ? this.imagePreview.attr("data-large") : this.imagePreview.attr("src");
			var description = this.imagePreview.attr("alt");

			if(!source) return;

			this.detailView.showImage(source, description, this);
		};

		this.uploader.on("change", function(event) {
			if(!supportsFileReader) return;

			var file = event.target.files[0];
			_self.selectFile(file);
		});

		this.selector.on("click", ".image-trigger", function(event) {
			if(_self.imagePreview.attr("src") !== _self.placeholder) {
				event.preventDefault();
				_self.showDetailView();
			} else {
				// only trigger the click event manually if the users browser is firefox between versions 3.6 and 21
				if(/Firefox\/(3\.6(\.\d+)?|[4-9]\.0(\.\d+)?|1[0-9]\.0(\.\d+)?|2[0-1]\.0(\.\d+)?)$/i.test(navigator.userAgent)) {
					$("#" + $(this).attr("for")).trigger("click");
				}
			}
		});

		this.resetPreviewImage = function() {
			this.imagePreview
				.attr("src", this.placeholder)
				.removeAttr("data-large")
			;
		};

		this.setImageDeleted = function() {
			this.deleter
				.prop("checked", true)
				.trigger("change")
			;
		};

		this.resetFileInput = function() {
			this.uploader
				.wrap("<form />")
				.closest("form")
				.get("0")
				.reset()
			;

			this.uploader.unwrap();
		};
	};

	ImageSelector.prototype = {
		removeImage: function() {
			this.resetPreviewImage();
			this.setImageDeleted();
			this.resetFileInput();
		},
		isSelectionRemovable: function() {
			return this.deleter.length !== 0;
		},
		getName: function() {
			return this.imageName
		}
	};

	var ImagePanel = function(panel) {
		var _self = this;

		this.panel = $(panel);
		this.placeholder = this.panel.attr("data-image-placeholder");
		this.detailView = new ImageDetailView(this.panel.find(".image-showcase"), this.placeholder);
		this.imageSelectors = {};

		this.panel.find(".image-selector").each(function() {
			var selector = new ImageSelector(this, _self.detailView, _self.placeholder);

			_self.imageSelectors[selector.getName()] = selector;
		});
	};

	$.fn.imageShowcaseRow = function() {
		return this.each(function() {
			return $(this).data("image-panel", new ImagePanel(this));
		});
	};
})(jQuery);
