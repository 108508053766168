(function($) {
	var FeedbackPanel = function($panel, options) {
		var messageSource = "<li class=\"feedback feedback-{{ level }}\">\n\t<span>{{ message }}</span>\n</li>";
		var $messages = $panel.find("> ul");
		$panel.addClass("feedback-panel-hide");

		if(!$messages.length) {
			$messages = $("<ul></ul>");
			$panel.append($messages);
		}

		this.feedbackMessageTemplate = Handlebars.compile(messageSource);
		this.feedbackMessages = $messages;
		this.panel = $panel;
	};

	FeedbackPanel.prototype = {
		toggleVisibility: function() {
			if(this.panel.hasClass("feedback-panel-hide")) {
				this.show();
			} else {
				this.hide();
			}
		},
		show: function() {
			this.panel.removeClass("feedback-panel-hide");
		},
		hide: function() {
			this.panel.addClass("feedback-panel-hide");
		},
		getMessageCount: function() {
			return this.feedbackMessages.children().length;
		},
		addMessage: function(message, level) {
			this.feedbackMessages.prepend(
				this.feedbackMessageTemplate({
					message: message,
					level: level
				})
			);
			this.panel.trigger("message");
		}
	};

	(function() {
		$.fn.feedbackPanel = function(method) {
			if(FeedbackPanel.prototype.hasOwnProperty(method)) {
				return FeedbackPanel.prototype[method].apply(this.data("feedbackPanel"), [].slice.call(arguments, 1));
			} else {
				return this.each(function() {
					var $panel = $(this);
					var options = options || {};

					var panel = new FeedbackPanel($panel, options);
					$panel.data("feedbackPanel", panel);

					if(panel.getMessageCount() > 0) {
						$panel.trigger("message");
					}

					return $panel;
				});
			}
		};
	})();
})(window.jQuery);
