jQuery(document).ready(function () {
	beautifyMultiSelects();
});

var openSelectsCounter = 0;

function closeMultiSelectOnLooseFocus() {
	$(".modal-dialog").click(function (e) {
		if ($(e.target)[0].className === "ms-choice" || $(e.target).parents("div.ms-parent").length > 0) {
			return;
		}

		$(".ms-choice > .open").parents(".ms-choice").click();
	});
}

function beautifyMultiSelects() {
	$(".multiple-select").each(function () {
		var options = {
			filter: true,
			onClose: doClose,
			onOpen: doOpen
		};
		var copyAttr = function (name, $source, target) {
			var value = $source.attr("data-".concat(name));
			if (value !== undefined) {
				target[name] = value;
			}
		};
		// These attributes have been taken from multiple.select.js default options
		copyAttr("selectAllText", $(this), options);
		copyAttr("allSelected", $(this), options);
		copyAttr("countSelected", $(this), options);
		copyAttr("noneSelected", $(this), options);
		options.selectType = true;

		$(this).multipleSelect(
			options
		);
	});
	if (document.querySelector("#existingCustomerPlatform") != null) {
		document.querySelector(".filter-container > button.button").style.display="";
	}
}

function removeBoilerplate(res) {
	var start = res.search("<form ");
	res = res.substring(start);
	var end = res.search("</form>");
	return res.substring(0, end + 7);
}

var ajaxFunction = function () {
	if (document.querySelector("#existingCustomerPlatform") != null) {
		return;
	}
	if(openSelectsCounter === 0 && document.getElementById("filterForm") != null) {
		var xhr = new XMLHttpRequest();
		xhr.addEventListener("load", function () {
			if(openSelectsCounter === 0) {
				document.getElementById("filterForm").innerHTML = removeBoilerplate(this.responseText);
				beautifyMultiSelects();
			}
		});
		xhr.open("GET", getAjaxRequest(), true);
		xhr.send();
	}
};

var doClose = function () {
	openSelectsCounter--;
	setTimeout(ajaxFunction, 1000);
};

var doOpen = function () {
	openSelectsCounter++;
};

function getAjaxRequest() {
	var request = "/bodyshops/market/intelligence/filter/only?";
	var selects = document.getElementById("filterForm").getElementsByTagName("select");
	for (var i = 0; i < selects.length; i++) {
		var select = selects[i];
		var options = select.options;
		for (var j = 0; j < options.length; j++) {
			var option = options[j];
			if (option.selected) {
				request = request + select.name + "=" + option.value + "&";
			}
		}
	}
	return request.substring(0, request.length - 1);
}
