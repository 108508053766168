(function ($) {
	var scrollElements;
	var positions = [];
	var $document = $(document);

	$.fn.keepInSight = function() {
		scrollElements = this;
		var scrolled = false;

		this.each(function() {
			var $element = $(this);
			var offset = parseInt($element.attr("data-keep-in-sight-offset"));
			var position = $element.offset().top;

			positions.push(isNaN(offset) ? position : position + offset);
		});

		window.setInterval(function() {
			if(!scrolled) return;

			var scrollTop = $document.scrollTop();

			for(var i = 0; i < positions.length; i++) {
				var scrollElement = $(scrollElements.get(i));

				if(positions[i] < scrollTop) {
					!scrollElement.hasClass("fixed") && scrollElement.addClass("fixed");
				} else {
					scrollElement.removeClass("fixed");
				}
			}

			scrolled = false;
		}, 100);

		$(window).on("scroll", function() {
			scrolled = true;
		});
	};

	$.keepInSightHeight = function(targetScrollPosition) {
		var height = 0;

		scrollElements.each(function(i) {
			height += (targetScrollPosition > positions[i]) ? ($(this).outerHeight() * 2) : $(this).outerHeight();
		});

		return height;
	};
})(jQuery);
