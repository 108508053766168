(function ($) {
	$.fn.scrollNav = function(navigation, options) {
		var navigatablePageSections = this;
		var scrolled = false;
		options = $.extend({
			heightRelevantSelectors: ".keep-in-sight.fixed",
			isActive: function($element) {
				return $element.attr("data-list-container-open") == "true";
			},
			getTarget: function($element) {
				return $element;
			}
		}, options || {});

		window.setInterval(function() {
			if(!scrolled) return;

			var currentScrollTop = $(document).scrollTop();
			var keepInSightHeight = 0;
			var isSectionActive = false;

			$(options.heightRelevantSelectors).each(function() { keepInSightHeight += $(this).outerHeight() });

			navigatablePageSections.each(function() {
				var $section = $(this);

				if(!options.isActive($section)) return;

				var section = {
					id: $section.attr("id"),
					top: $section.offset().top - keepInSightHeight - 5,
					height: $section.height(),
					bottom: $section.offset().top + $section.height() - keepInSightHeight + 5
				};

				if(currentScrollTop >= section.top && currentScrollTop <= section.bottom) {
					$(this).addClass("currently-viewed");

					options
						.getTarget(navigation.find("a"))
						.removeClass("active");

					options
						.getTarget(navigation.find("a[href='#" + section.id +"']"))
						.addClass("active");

					isSectionActive = true;
					return false;
				}
			});

			if(!isSectionActive) {
				options
					.getTarget(navigation.find("a"))
					.removeClass("active");

				navigatablePageSections.removeClass("currently-viewed");
			}

			scrolled = false;
		}, 100);

		$(document).on("scroll", function(event) {
			scrolled = true;
		});
	};
})(jQuery);
