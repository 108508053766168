jQuery.fn.docketChart = function (options) {
  $("[role=docket]").each(function() {
    var $this = $(this);
    var $docketChart = $this.find("[role=chart]");
    var $docketData = $this.find("[role=provider]");
    var $docketSwitch = $this.find("[role=switch]");

    $docketData.find("input[type='text']").each(function(i, input) {
      $(input).bind("change", function() {
        refreshChart($docketChart, $docketData);
      });
    });

    $docketSwitch.bind("click", function() {
      if ($docketChart.is(":visible")) {
        $docketChart.hide();
        $docketData.show();
      } else {
        refreshChart($docketChart, $docketData);
        $docketData.hide();
        $docketChart.show();
      }
      ;
    });

    refreshChart($docketChart, $docketData);
    if ($docketChart.data("hide-chart") !== 'never') {
      $docketChart.hide();
    }
  });

  function refreshChart($docketChart, $docketData) {
    var series = [];
    $docketData.find("th[slot]").each(
      function(i, th) {
        var $th = $(th);
        var slot = $th.attr("slot");
        series[slot] = {
          color : $th.attr('color'),
          name : $th.text(),
          values : []
        };

        $docketData.find("td[slot=" + slot + "]").each(
          function(j, td) {
            var value = $(td).find("input").val() || $(td).find("span").text();
            series[slot].values.push(value === "" ? null
              : parseFloat(value));
          });
      });

    var data = {
      title : $docketChart.attr("title"),
      xTitle : $docketChart.attr("xTitle"),
      yTitle : $docketChart.attr("yTitle"),
      series : series
    };
    var generator = new de.ittecture.chart.generator.DocketAnswerChartGenerator(
      data);
    var args = generator.generateRenderOptions();
    var chartExportConf = $docketChart
      .attr("data-chart-export-configuration");
    var conf = $.parseJSON(chartExportConf);
    args.exporting = $.extend(args.exporting, conf.exporting);
    args.chart = $.extend(args.chart, conf.chart);

    $docketChart.highcharts(args, function(chart) {
      if (typeof generator.onComplete === "function") {
        generator.onComplete.call(generator, chart);
      }
    });
  }
};