(function(component_name) {
	var NumberEditor = function(el, data) {
		var self = this;

		this.el = el;
		this.sources = {};
		this.sources.show_for = $('[data-question-editor="input_show_for"]').inputInterface();
		this.sources.default_answer = $('[data-question-editor="default_answer"]').inputInterface();
	};

	NumberEditor.prototype = {
	};

	rivets.components[component_name] = {
		template: function() {
			var template = "<div class=\'rv-editor-section\'>\n\t<div class=\'rv-editor-subsection\'>\n\t\t<div class=\'row\'>\n\t\t\t<div rv-grid=\"\'l:5\'\">\n\t\t\t\t<app-authoring-show-for source=\"sources.show_for\"></app-authoring-show-for>\n\t\t\t</div>\n\t\t\t<div rv-grid=\"\'l:7\'\">\n\t\t\t\t<div rv-show=\"sources.default_answer\" class=\'rv-editor-subsection\'>\n\t\t\t\t\t<label for=\"sources.default_answer.custom_id | prefix \'authoring_\'\" rv-text=\'sources.default_answer.label\'></label>\n\t\t\t\t\t<input type=\'number\' rv-disabled=\"sources.default_answer.is_disabled\" rv-value=\"sources.default_answer.value\" />\n\t\t\t\t</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
			return template;
		},
		initialize: function(el, data) {
			return new NumberEditor(el, data);
		}
	};
})("app-authoring-number");
