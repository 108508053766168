(function(ns) {
	var SingleBodyShopChartGenerator = function(data, benchmarkStyles) {
		this.data = data;
		this.benchmarkStyles = benchmarkStyles;
		this.defaultHighChartRenderOptions = {
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			chart: {
				height: 600,
				type: "column",
				spacingTop: 0,
				spacingLeft: 0,
				spacingRight: 0,
				spacingBottom: 20,
				backgroundColor: "transparent"
			},
			title: {
				text: null
			},
			subtitle: {
				text: null
			},
			xAxis: {
				categories: [],
				gridLineWidth: 1,
				gridLineDashStyle: "ShortDot"
			},
			yAxis: {
				min: 0,
				max: 100,
				tickInterval: 20,
				title: {
					text: null,
					style: {
						color: "#666666"
					}
				},
				showLastLabel: false,
				gridLineDashStyle: "ShortDot"
			},
			plotOptions: {
				column: {
					animation: false,
					borderWidth: 1,
					pointWidth: 50,
					enableMouseTracking: false,
					dataLabels: {
						enabled: true,
						inside: true,
						verticalAlign: "top",
						style: {
							fontWeight: "bold",
							textShadow: false
						}
					}
				}
			},
			series: [{
				data: []
			}],
			tooltip: {
				enabled: false
			}
		};
	};

	SingleBodyShopChartGenerator.prototype.generateRenderOptions = function() {
		var options = this.defaultHighChartRenderOptions;

		options.title.text = this.data.title;
		options.subtitle.text = this.data.subtitle;
		options.yAxis.title.text = this.data.yTitle;

		for(var i = 0; i < this.data.columns.length; i++) {
			var column = this.data.columns[i];
			var style = this.benchmarkStyles[column.colorClass];

			options.xAxis.categories.push(column.label);
			options.series[0].data.push({
				y: column.value,
				color: style.backgroundColor,
				borderColor: style.borderColor,
				dataLabels: {
					color: style.color
				}
			});
		}

		return options;
	};

	ns.SingleBodyShopChartGenerator = SingleBodyShopChartGenerator;
})(de.ittecture.chart.generator);