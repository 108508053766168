(function($) {
	var data_key = "input_interface";

	$.fn.inputInterface = function() {
		if(typeof(this.data(data_key)) === "undefined") {
			this.data(data_key, de.ittecture.utils.input_interface.create(this));
		}

		return this.data(data_key);
	};
})(jQuery);
