(function($) {
	$(document).ready(function() {
		if($("#questionnaire-authoring").length === 0) return;

		var $questionnaireContainer = $(".container-list");
		var listContainer = $questionnaireContainer.listContainer({
			openAnimationSpeed: 0,
			closeAnimationSpeed: 0,
			autoOpenOnSelector: ".error"
		}).data("listContainer");
		
		$(".question-set select").selectExpander();
	});
})(jQuery);