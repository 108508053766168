(function(ns) {
	var BodyShopDataset = function(settings) {
		this.datumTokenizer = settings.datumTokenizer || Bloodhound.tokenizers.obj.whitespace("value");
		this.queryTokenizer = settings.queryTokenizer || Bloodhound.tokenizers.whitespace;
		this.templates = settings.templates || null;

		this.getSearchUrl = function() {
			var baseUrl = settings.baseUrl;

			var uriComponents = settings.searchParameters || {};
			uriComponents["name"] = "%QUERY";

			return baseUrl + "?" + (function() {
				var params = [];

				for(name in uriComponents) {
					if(!uriComponents.hasOwnProperty(name)) continue;

					params.push(name + "=" + uriComponents[name]);
				}

				return params.join("&");
			})();
		};

		this.getEngine = function() {
			if(!this._engine) {
				var engine = new Bloodhound({
					datumTokenizer: this.datumTokenizer,
					queryTokenizer: this.queryTokenizer,
					limit: 10,
					remote: {
						url: this.getSearchUrl(),
						filter: function(response) {
							var bodyShops = [];

							for(var i = 0, ii = response.length; i < ii; i++) {
								var bs = response[i];

								if(bs.location && bs.location.latitude !== -1 && bs.location.longitude !== -1) {
									bodyShops.push(bs);
								}
							}

							return bodyShops;
						}
					}
				});

				engine.clearRemoteCache();
				engine.initialize();
				this._engine = engine;
			}

			return this._engine;
		}
	};

	BodyShopDataset.prototype = {
		name: "bodyshops",
		createQueryFactory: function() {
			return this.getEngine().ttAdapter();
		},
		createTypeaheadDataset: function() {
			return {
				name: this.name,
				displayKey: "name",
				source: this.createQueryFactory(),
				templates: this.templates
			};
		}
	};

	ns.BodyShopDataset = BodyShopDataset;
})(de.ittecture.typeahead.dataset);