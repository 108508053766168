jQuery(document).ready(function ($) {
	if ($("#dashboard").length === 0)
		return;
	
	$(".identity-control").identityControlPanel();

	$(".chart").each(function() {
		var cssClassesData = $(this).data("css-classes");
		var options = cssClassesData 
			? {
				cssClasses: [
					cssClassesData.split(","),
					cssClassesData.split(","),
					cssClassesData.split(",")
				]
			}
			: {};
		$(this).dashboardChart(options);
	});
	$(".ring-chart").each(function() {
		$(this).dashboardRingChart();
	});
	$(".brand-chart").each(function() {
		$(this).dashboardBrandChart();
	});
	$(".brand-bar-chart").each(function() {
		$(this).dashboardBrandBarChart();
	});
	$(".split-ring-chart").each(function() {
		$(this).dashboardSplitRingChart();
	});

	$('.identity-control select:not([multiple])').chosen({
		allow_single_deselect: true,
		width: "100%"
	});
	
	$('.identity-control select[multiple]').each(function() {
		var options = {
			filter : true
		};
		var copyAttr = function(name, $source, target) {
			var value = $source.attr("data-".concat(name));
			if (value !== undefined) {
				target[name] = value;
			}
		};
		// These attributes have been taken from multiple.select.js default options
		copyAttr('selectAllText', $(this), options);
		copyAttr('allSelected', $(this), options);
		copyAttr('countSelected', $(this), options);
		copyAttr('noneSelected', $(this), options);
		options['selectType'] = true;
		
		$(this).multipleSelect(
			options
		);
	});

});