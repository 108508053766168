(function(component_name) {
	var SimpleBooleanEditor = function(el, data) {
		var self = this;

		this.el = el;
		this.sources = {};
		this.sources.default_answer = $('[data-question-editor="default_answer"]').inputInterface();
	};

	SimpleBooleanEditor.prototype = {
	};

	rivets.components[component_name] = {
		template: function() {
			var template = "<div class=\'rv-editor-section\'>\n\t<div class=\'rv-editor-subsection\'>\n\t\t<div class=\'row\'>\n\t\t\t<div rv-grid=\"\'l:8\'\">\n\t\t\t\t<app-form-expanded-select source=\"sources.default_answer\"></app-form-expanded-select>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>";
			return template;
		},
		initialize: function(el, data) {
			return new SimpleBooleanEditor(el, data);
		}
	};
})("app-authoring-simple-boolean");
