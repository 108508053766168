window.de = window.de || {};
window.de.ittecture = {
	utils: {},
	map: {},
	chart: {
		generator: {}
	},
	typeahead: {
		dataset: {}
	}
};