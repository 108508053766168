(function(ns) {
	var AnalysisGroupChartGenerator = function(data, benchmarkStyles) {
		this.data = data;
		this.benchmarkStyles = benchmarkStyles;
		this.defaultHighChartRenderOptions = {
			legend: {
				enabled: false
			},
			credits: {
				enabled: false
			},
			chart: {
				animation: false,
				height: 600,
				type: "bubble",
				plotBorderWidth: 1,
				spacingTop: 0,
				spacingLeft: 8,
				spacingRight: 0,
				spacingBottom: 20,
				backgroundColor: "transparent"
			},
			title: {
				text: null
			},
			subtitle: {
				text: null
			},
			xAxis: {
				gridLineWidth: 1,
				gridLineDashStyle: "ShortDot",
				categories: null,
				showLastLabel: false,
				title: {
					text: null,
					offset: 30,
					style: {
						color: "#666666"
					}
				}
			},
			yAxis: {
				min: 0,
				max: 100,
				tickInterval: 20,
				endOnTick: false,
				title: {
					text: null,
					offset: 60,
					style: {
						color: "#666666"
					}
				},
				showLastLabel: false,
				showFirstLabel: false,
				gridLineDashStyle: "ShortDot",
				plotBands: []
			},
			plotOptions: {
				bubble: {
					minSize: 12,
					maxSize: 12
				}
			},
			series: [],
			tooltip: {
				enabled: true,
				formatter: function() {
					return this.point.y + "% " + this.point.name;
				}
			}
		};
	};

	AnalysisGroupChartGenerator.prototype.generateRenderOptions = function() {
		var options = this.defaultHighChartRenderOptions;
		var series = {};

		options.title.text = this.data.title;
		options.subtitle.text = this.data.subtitle;
		options.yAxis.title.text = this.data.yTitle;
		options.xAxis.title.text = this.data.xTitle;

		for(var i = 0, length = this.data.benchmarks.length; i < length; i++) {
			var benchmark = this.data.benchmarks[i];

			if(!series.hasOwnProperty(benchmark.colorClass)) {
				series[benchmark.colorClass] = {
					data: [],
					color: this.benchmarkStyles[benchmark.colorClass].color,
					marker: {
						fillOpacity: 0.3
					}
				};
			}

			series[benchmark.colorClass].data.push({
				x: benchmark.numberOfRepairs,
				y: benchmark.totalBenchmark,
				name: benchmark.bodyshopName
			});
		}

		for(var classication in series) {
			options.series.push(series[classication]);
		}

		for(i = 0, length = this.data.benchmarkGroups.length; i < length; i++) {
			var benchmarkGroup = this.data.benchmarkGroups[i];

			options.yAxis.plotBands.push({
				color: this.benchmarkStyles[benchmarkGroup.colorClass].backgroundColor,
				from: benchmarkGroup.boundaries.bottom,
				to: benchmarkGroup.boundaries.top,
				label: {
					text: benchmarkGroup.name,
					rotation: -90,
					align: "left",
					textAlign: "center",
					x: -30,
					style: {
						color: this.benchmarkStyles[benchmarkGroup.colorClass].color
					}
				}
			});
		}

		return options;
	};

	ns.AnalysisGroupChartGenerator = AnalysisGroupChartGenerator;
})(de.ittecture.chart.generator);