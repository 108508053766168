function hideUnprotectionErrorMsgOnSelection() {

	function hideErrorHint() {
		document.querySelector("#bodyshop-map-search label.error").style.display = "none";
	}

	Array.from(document.querySelectorAll("#bodyshop-map-search input[type=radio]"))
		.forEach(function (radio) {
				radio.addEventListener("change", hideErrorHint);
			}
		);
}

function refreshPage() {
	location.reload();
}
