(function(component_name) {
	var Component = function(config) {
		var self = this;
		this.source = config.source;
		this.inline = config.inline !== "false";
		this.type = config.type || this.source.expand_type;
		this.option_treshold = config.option_treshold || false;
		this.translator = config.translator || null;
		this.grid_size = config.grid_size || null;
		this.disable_label = config.disable_label || false;

		Object.defineProperty(this, "filter", {
			get: function() {
				return self._filter || "";
			},
			set: function(value) {
				self._filter = value;
				self.source.options.forEach(function(option) {
					value = value.toLowerCase();
					option.is_filtered =
						option.label.toLowerCase().indexOf(value) === -1 &&
						option.value.toLowerCase().indexOf(value) === -1;
				})
			}
		});

		if(config.filter) {
			config.filter.attach(function(value) {
				self.filter = value;
			});
		}
	};

	Component.prototype = {
		show_filter: function() {
			return this.option_treshold && this.source.options.length > this.option_treshold;
		}
	};

	rivets.components[component_name] = {
		static: ["type", "option_treshold", "grid_size", "disable_label", "inline"],
		template: function() {
			var template = "<div class=\"select-expander-container\">\n\t<label rv-unless=\"disable_label\" rv-for=\"source.custom_id\" rv-text=\"source.label\"></label>\n\t<label rv-show=\"show_filter\" class=\'select-expander-filter\'>\n\t\t<input type=\"search\" rv-value=\"filter\"  placeholder=\'Filter Values...\' />\n\t\t<span class=\'hide\'>Filter values</span>\n\t</label>\n\t<ul class=\"select-expander\" rv-id=\"source.custom_id\" rv-class-select-expander-inline=\"inline\" rv-class-row=\"grid_size\">\n\t\t<li rv-each-option=\"source.options\" rv-class-select-expander-filtered=\"option.is_filtered\" rv-grid-distributed=\"grid_size\">\n\t\t\t<label class=\"select-expander-option\">\n\t\t\t\t<input rv-name=\"name\" rv-value=\"option.value\" rv-checked=\"option.selected < last_change\" rv-type=\"type\" />\n\t\t\t\t<span rv-text=\"option.label | trans translator\"></span>\n\t\t\t</label>\n\t\t</li>\n\t</ul>\n</div>";
			return template;
		},
		initialize: function(el, data) {
			return new Component(data);
		}
	};
})("app-form-expanded-select");
