(function ($) {
	$.fn.stickTo = function ($boundTo, options) {
		var $sticky = $(this);
		var hideTimeout;
		var stickyOffset = $sticky.outerHeight() / 2;
		var settings = $.extend({
			animationSpeed: "fast"
		}, options || {});

		$boundTo.on("mouseenter", function() {
			clearTimeout(hideTimeout);

			var $this = $(this);
			var position = {
				top: $this.offset().top + $this.outerHeight() / 2 - stickyOffset,
				left: $this.offset().left + $this.outerWidth() + 10
			};

			if($sticky.is(":hidden")) {
				$sticky
					.css(position)
					.fadeIn(settings.animationSpeed)
				;
			} else {
				$sticky
					.animate(position, {
						duration: settings.animationSpeed,
						queue: false
					})
					.fadeIn(settings.animationSpeed)
				;
			}
		});

		$boundTo.on("mouseleave", function() {
			hideTimeout = setTimeout(function() {
				$sticky.fadeOut(settings.animationSpeed);
			}, 200);
		});
	}
})(jQuery);
