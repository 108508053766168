(function($) {
	var MapLab = function(element, options) {
		var _ = this;

		this.searchContainer = $(element.find("[data-maplab-control='search']"));
		this.search = $(this.searchContainer.find("input"));
		this.map = $(element.find("[data-maplab-control='map']"));

		async.parallel([
			function(callback) {
				var typeaheadOptions = $.extend({}, {
					hint: true,
					highlight: true,
					minLength: 1
				}, options.typeahead.options || {});

				_.search.typeahead(typeaheadOptions, options.typeahead.datasets);

				callback();
			},
			function(callback) {
				// intentional. see #672
				setTimeout(callback, 250);
			}
		], function() {
			element.trigger("maplab:initialized");
		});

		this.search.on("typeahead:selected typeahead:autocompleted", function(event, selection, dataset) {
			element.trigger("maplab:select", [selection, dataset]);
		});

		this.search.on("blur", function() {
			_.searchContainer.removeClass("active");
		});

		this.search.on("focus", function() {
			_.searchContainer.addClass("active");
		});

		$(document).on("keydown", function(event) {
			if(event.keyCode === 83 && !_.search.is(":focus") && $("input:focus, textarea:focus, select:focus").length === 0) {
				event.preventDefault();
				_.focusSearch();
			}
		});
	};

	MapLab.prototype = {
		focusSearch: function() {
			this.search.focus().select();
			this.searchContainer.addClass("active");
		}
	};

	$.fn.maplab = function(method) {
		if(MapLab.prototype.hasOwnProperty(method)) {
			return MapLab.prototype[method].apply(this.data("mapLab"), [].slice.call(arguments, 1));
		} else {
			return this.each(function() {
				var $mapLab = $(this);
				var options = method || {};

				var mapLab = new MapLab($mapLab, options);
				$mapLab.data("mapLab", mapLab);

				return $mapLab;
			});
		}
	};
})(window.jQuery);
