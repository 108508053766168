(function(ns) {
	var ComparisonChartGenerator = function(data, benchmarkStyles) {
		this.data = (function() {
			if(data.hasOwnProperty("target")) {
				data.target.categories.unshift(data.target.total);
			}

			return data;
		})();
		this.benchmarkStyles = benchmarkStyles;
		this.defaultHighChartRenderOptions = {
			legend: {
				borderWidth: 0,
				symbolWidth: 0,
				useHTML: true,
				lineHeight: 18,
				itemStyle: {
					color: "#666"
				},
				itemHoverStyle: {
					color: "#666",
					cursor: "default"
				},
				labelFormatter: function() {
					return "<span style='background: #dddddd; margin-left: -8px; border-radius: 100%; display: inline-block; width: 18px; text-align: center; line-height: 18px'>" + (this.index + 1) + "</span> " + this.name;
				}
			},
			credits: {
				enabled: false
			},
			chart: {
				height: 600,
				type: "column",
				spacingTop: 0,
				spacingLeft: 8,
				spacingRight: 0,
				spacingBottom: 20,
				backgroundColor: "#f9f9f9"
			},
			title: {
				text: null
			},
			subtitle: {
				text: null
			},
			xAxis: {
				gridLineWidth: 1,
				gridLineDashStyle: "ShortDot",
				categories: null
			},
			yAxis: {
				min: 0,
				max: 100,
				tickInterval: 20,
				title: {
					text: null,
					style: {
						color: "#666666"
					}
				},
				showLastLabel: false,
				gridLineDashStyle: "ShortDot"
			},
			defs: {
		        patterns: [{
		            'id': 'custom-pattern',
		            'path': {
		                d: 'M 0 10 L 10 0 M -1 1 L 1 -1 M 9 11 L 11 9',
		                stroke: '#BDBDBD',
		                strokeWidth: 1
		            }
		        }]
		    },
			plotOptions: {
				series: {
					events: {
						legendItemClick: function() {
							return false;
						}
					}

				},
				column: {
					animation: false,
					borderWidth: 2,
					pointWidth: 30,
					pointRange: 1.5,
					dataLabels: {
						enabled: true,
						inside: true,
						verticalAlign: "top",
						style: {
							fontWeight: "bold",
							textShadow: false
						}
					}
				}
			},
			series: [],
			tooltip: {
				borderColor: "#bbbbbb",
				formatter: function() {
					return this.series.name;
				}
			}
		};
	};

	ComparisonChartGenerator.prototype.generateRenderOptions = function() {
		var options = this.defaultHighChartRenderOptions;

		options.title.text = this.data.title;
		options.subtitle.text = this.data.subtitle;
		options.yAxis.title.text = this.data.yTitle;
		options.xAxis.categories = this.data.selectorNames;

		for(var i = 0; i < this.data.categoryNames.length; i++) {
			var series = {
				name: this.data.categoryNames[i],
				data: []
			};

			for(var j = 0; j < this.data.columns.length; j++) {
				var column = this.data.columns[j][i];
				var style = this.benchmarkStyles[column.colorClass];

				series.data.push({
					y: column.value,
					marker: {
						borderColor: style.borderColor,
						color: style.backgroundColor,
						states: {
							hover: {
								borderColor: style.borderColor,
								color: style.backgroundColor
							}
						}
					},
					dataLabels: {
						color: style.color
					}
				});
			}

			options.series.push(series);
		}

		return options;
	};

	ComparisonChartGenerator.prototype.onComplete = function(chart) {
		if(!this.data.hasOwnProperty("target")) return;

		var target = this.data.target;
		var chartPlotOrigin = {
			y: chart.chartHeight - chart.marginBottom,
			x: chart.plotLeft
		};

		for(var i = 0, numCategories = target.categories.length; i < numCategories; i++) {
			var series = chart.series[i];
			var category = target.categories[i];

			for(var j = 0, numPoints = series.points.length; j < numPoints; j++) {
				var point = series.points[j];

				if(!point.plotY) continue;
				
				var targetShape = {
					x: chartPlotOrigin.x + point.barX,
					y: chartPlotOrigin.y * (100 - category.value) / 100,
					width: point.shapeArgs.width + point.graphic["stroke-width"] - 2,
					height: chartPlotOrigin.y * category.value / 100
				};

				// there is not enough space available to render both the actual point label and the target label
				// if the two values are not +-4
				if(Math.abs(point.y - category.value) > 3) {
					chart.renderer.text(category.value, targetShape.x + 8, targetShape.y + 16)
						.css({
							color: "#666666"
						})
						.add();
				}

				chart.renderer.rect(targetShape.x, targetShape.y, targetShape.width, 2)
					.attr({
						fill: "#666666",
						zIndex: 100
					})
					.add();

				chart.renderer.rect(targetShape.x, targetShape.y, targetShape.width, targetShape.height)
					.attr({
						fill: 'url(#custom-pattern)'
					})
					.add();
			}
		}
	};

	ns.ComparisonChartGenerator = ComparisonChartGenerator;
})(de.ittecture.chart.generator);