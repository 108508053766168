(function($) {
	var toggleTemplateSource = "<div role=\"listbox\" class=\"toggle-box\" data-toggle-reference=\"{{ reference }}\">\n\t<a role=\"option\" href=\"#\" class=\"toggle toggle-on\" aria-selected=\"{{#if isSelected }}true{{else}}false{{/if}}\">\n\t\t{{ toggleOnLabel }}\n\t</a>\n\t<a role=\"option\" href=\"#\" class=\"toggle toggle-off\" aria-selected=\"{{#if isSelected }}false{{else}}true{{/if}}\">\n\t\t{{ toggleOffLabel }}\n\t</a>\n</div>";
	var toggleTemplate = Handlebars.compile(toggleTemplateSource);

	var Toggle = function($toggle, $reference, options) {
		var toggle = this;

		this.changeSelection = function(state) {
			$reference.trigger("change");
			$toggle
				.find(".toggle-on")
				.attr("aria-selected", state ? "true" : "false");

			$toggle
				.find(".toggle-off")
				.attr("aria-selected", state ? "false" : "true");
		};

		this.toggleOn = function() {
			$reference.prop("checked", true);
			toggle.changeSelection(true);
		};

		this.toggleOff = function() {
			$reference.prop("checked", false);
			toggle.changeSelection(false);
		};

		if(options.disabled) return;

		$toggle.on("click", ".toggle", function(event) {
			event.preventDefault();
			event.stopPropagation();

			toggle[$(this).hasClass("toggle-on") ? "toggleOn" : "toggleOff"]();
		});
	};

	(function() {
		var methods = {
			initialize: function(options) {
				return this.each(function () {
					var $this = $(this);
					var options = options || {};
					options.disabled = $this.is(":disabled");

					var config = {
						reference: $this.attr("id"),
						isSelected: $this.is(":checked"),
						toggleOnLabel: $this.attr("data-label-on"),
						toggleOffLabel: $this.attr("data-label-off")
					};

					var $toggle = $(toggleTemplate(config));
					$toggle.attr("aria-disabled", options.disabled);
					var toggle = new Toggle($toggle, $this, options);
					$toggle.data("toggle", toggle);

					$this.after($toggle);
				});
			},
			toggleOn: function() {
				this.toggleOn();
			},
			destroy: function() {

			}
		};

		var oToggle = $.fn.toggle;
		$.fn.toggle = function(method) {
			if(this.length === 0) return this;

			if (method === "initialize") {
				return methods.initialize.apply(this, arguments);
			} else if(methods[method]) {
				var toggle = this.data("toggle");

				if(toggle instanceof Toggle) {
					return methods[method].apply(toggle, [].slice.call(arguments, 1));
				} else {
					throw "You’re calling a toggle method on an element that is not a toggle component";
				}
			} else {
				return oToggle.apply(this, arguments);
			}
		};
	})();
})(window.jQuery);
