(function($) {
	$(document).ready(function() {
		if($("#bodyshop-interview").length === 0) return;

		var $interviewContainer = $(".container-list");
		var listContainer = $interviewContainer.listContainer({
			openAnimationSpeed: 0,
			closeAnimationSpeed: 0,
			autoOpenOnSelector: ".error"
		}).data("listContainer");

		$(".container-toggle input").toggle("initialize");

		$("[data-pony-prototype]").pony();

		$(".page-header .button-toolbar a, .category-navigator a").on("click", function(event) {
			event.preventDefault();
			listContainer.openContainer($(this).attr("href"), function(skipTo) {
				skipTo();
			});
		});

		$("[data-list-container-item]").scrollNav($(".category-navigator"), {
			heightRelevantSelectors: "",
			getTarget: function($element) { return $($element.closest(".category-navigator-item")); }
		});

		$("form").formSubmitPrevention();

		$(".container-list-content").on("change", "input, select, textarea", function () {
			var tBox = $(this).closest(".container-list-item").find(".toggle-box");
			var isUnSupported = tBox.find(".toggle-off").attr("aria-selected");
			if (isUnSupported) {
				tBox.find(".toggle-on").attr("aria-selected", "true");
				tBox.find(".toggle-off").attr("aria-selected", "false");
			}
		});
    
    $(this).docketChart();

		var averageRefresh = function($event, selectors, dataArea) {
			var sum = 0;
			var target = $event.target;
			var answer = $(target).closest(selectors);
			var addends = $(answer).find("[data-sum="+dataArea+"]");
			var amount = 0;
			$(addends).each(function() {
				if(this.value) {
					var value = parseFloat(this.value);
					sum += value;
					amount++;
				}
			});
			var average = "";
			if(amount > 0){
				average = sum / amount;
			}
			
			$(answer).find("[data-area="+dataArea+"]").text(average);
			$(answer).find("[hidden-data-area="+dataArea+"]").val(average);
		};		
		
		var totalRefresh = function($event) {
			var target = $event.target;
			var airspeedCalculation = $(target).closest(".airspeed-calculation");
			var $airspeedCalculation = $(airspeedCalculation);
			
			var averageView = $airspeedCalculation.find("[data-area='speedAverage']");
			var average = averageView.text() ? parseFloat(averageView.text()) : -1;
			var lengthView = $airspeedCalculation.find("[data-area='ceilingLength']");
			var length = lengthView.val() ? parseFloat(lengthView.val()) : -1;
			var widthView = $airspeedCalculation.find("[data-area='ceilingWidth']");
			var width = widthView.val() ? parseFloat(widthView.val()) : -1;
			var total = average < 0 || length < 0 || width < 0 ? '' : average * length * width * 3600;
			
			$airspeedCalculation.find("[data-area='speedTotal']").text(total);
			$airspeedCalculation.find("[hidden-data-area='speedTotal']").val(total);
		};
		
		var speedAverageRefresh = function($event) {
			averageRefresh($event, ".airspeed-answer", "speedAverage")
		};
		var lightingAverageRefresh = function($event) {
			averageRefresh($event, ".lighting-answer", "lightingAverage")
		};
		
		$("[data-sum='speedAverage']").bind('change', speedAverageRefresh);
		$("[data-sum='lightingAverage']").bind('change', lightingAverageRefresh);
		$("[data-area='speedAverage']").bind('DOMSubtreeModified', totalRefresh);
		$("[data-area='ceilingLength']").bind('change', totalRefresh);
		$("[data-area='ceilingWidth']").bind('change', totalRefresh);
		
		// update on page load
		$(".airspeed-answer").each(function() {
			averageRefresh({target: $(this)}, ".airspeed-answer", "speedAverage");
			totalRefresh({target: $(this)});
		});

		$(".lighting-answer").each(function() {
			averageRefresh({target: $(this)}, ".lighting-answer", "lightingAverage");
		});

		var abortButton = $("[name='goBackToOverview']");
		if (abortButton) {
			abortButton.userMessage({
				actions: {
					"yes": function () {
						window.location.href = abortButton.attr('href');
					}
				}
			});
		}

		var bodyShopButton = $("[name='goBackToBodyShop']");
		if (bodyShopButton) {
			bodyShopButton.userMessage({
				actions: {
					"yes": function () {
						window.location.href = bodyShopButton.attr('href');
					}
				}
			});
		}
	});
})(jQuery);

(function ($) {
	window.answerQuestionnaire = function () {
		$(".form-row-input .number").each(function () {
			$(this).val(Math.floor((Math.random() * 10) + 1));
		});

		for(var i = 1; i < 7; i++){
			$("input[type='text'].number.slot"+i).each(function(){
				$(this).val(Math.floor((Math.random() * 99) + 10));				
			});
		}

		$(".form-row-input input[type='text']").not(".number").each(function () {
			$(this).val(Math.random().toString(36).substring(7));
		});
		$(".interview-item").each(function () {
			var $this = $(this);
			var $options = $this.find(".pony-option");
			$options.first().trigger("click");
			if ($.trim($options.first().find("span").text()) === "Ja" && $options.size() > 2) {
				$options.eq(1).trigger("click");
			}
		});
	};
})(jQuery);

$(document).ready(function () {
	var toggles = document.querySelectorAll(".container-toggle");
	for(i=0; i < toggles.length; i++){
		var currentToggle = toggles[i];
		var content = currentToggle
			.parentElement
			.parentElement
			.querySelector(".interview-item");
		if (content === null) {
			currentToggle.setAttribute("style", "display:none");
		}
	}
});
