document.addEventListener("DOMContentLoaded", function () {
	google.maps.event.addDomListener(window, 'load', initMap());
});

var current;

function getImageNameByColorCode(color) {
	switch (color) {
		case "#f90990":
			return "akzoLesonal.png";
		case "#7bbc3a":
			return "AkzoSalcomix.png";
		case "#c0c0c0":
			return "akzoSikkens.png";
		case "#09c1f9":
			return "axaltaCromax.png";
		case "#f9e109":
			return "axaltaIxell.png";
		case "#000000":
			return "AxaltaSpiesHecker.png";
		case "#16b8e7":
			return "axaltaStandox.png";
		case "#8e001e":
			return "baslac.png";
		case "#afffe8":
			return "Bernado.png";
		case "#4b39bc":
			return "Colorsystem.png";
		case "#0F3161":
			return "glasurit.png";
		case "#bc3873":
			return "HeliosMobihel.png";
		case "#914c24":
			return "ICR.png";
		case "#ffafbe":
			return "LechlerLechler.png";
		case "#498e1d":
			return "Mader.png";
		case "#21ff00":
			return "MaederLacke.png";
		case "#ff9138":
			return "Mankiewicz.png";
		case "#b5ffaf":
			return "MipaMipa.png";
		case "#f0ffaf":
			return "Monopole.png";
		case "#808080":
			return "others.png";
		case "#afbcff":
			return "PalinalPailnal.png";
		case "#fff7af":
			return "ppgMaxMeyer.png";
		case "#ffff00":
			return "ppgNexa.png";
		case "#008000":
			return "ppgppg.png";
		case "#FC0001":
			return "rm.png";
		case "#ff6682":
			return "Roberlo.png";
		case "#8e8b00":
			return "selemix.png";
		case "#c1982e":
			return "SherwinWilliams.png";
		case "#66dbff":
			return "ToyotaPaint.png";
		case "#f9a109":
			return "valspardabeer.png";
		case "#7838bc":
			return "ValsparOctoral.png";
		case "#3070a8":
			return "ValsparValspar.png";
		case "#008e1a":
			return "vw.png";
		case "#09f9f1":
			return "whiteLabel.png";
		case "#c6b37f":
			return "Woerwag.png";
		default:
			return "NotGiven.png"
	}
}

function initMap() {
	if ($("#bodyshop-map-search").length === 0) return;


	var url_string = window.location.href;
	var url = new URL(url_string);

	var endLatitude = url.searchParams.get("endLatitude");
	var endLongitude = url.searchParams.get("endLongitude");
	var startLatitude = url.searchParams.get("startLatitude");
	var startLongitude = url.searchParams.get("startLongitude");

	var bounds = new google.maps.LatLngBounds();

	var defaultLocation = {
		lat: 51.133481,
		lng: 10.018343
	};

	var defaultZoom = 6;
	var map = new google.maps.Map(document.querySelector('[data-maplab-control="map"]'), {
		center: defaultLocation,
		zoom: defaultZoom,
		streetViewControl: true
	});
	
	if (endLatitude) {
		var start = new google.maps.LatLng(startLatitude, startLongitude);
		var end = new google.maps.LatLng(endLatitude, endLongitude);

		bounds.extend(start);
		bounds.extend(end);
		map.fitBounds(bounds);
		map.panToBounds(bounds);
	}

	var $maplab = $(".maplab");

	var sessionToken = new google.maps.places.AutocompleteSessionToken();
	var service = new google.maps.places.AutocompleteService();

	var bodyShopDataset = new de.ittecture.typeahead.dataset.BodyShopDataset({
		baseUrl: $maplab.attr("data-bodyshop-dataset-url"),
		templates: {
			header: $("#typeahead-bodyshop-header").loadTemplate()
		}
	});

	var formatAddress = function (predictions, status) {
		var formatttedAddress = [];
		var placeObj = {};
		if (status === "OK") {
			predictions.forEach(function (prediction) {
				placeObj.address = prediction.description;
				placeObj.placeId = prediction.place_id;
				formatttedAddress.push(placeObj)
				placeObj = {};
			});
		}
		return formatttedAddress;
	}

	$(".typeahead").on('typeahead:selected typeahead:autocompleted', function (e, datum) {
		// this input is the input that needs the imdb_id value
		//alert(datum.placeId);
		var container = document.getElementById("container");
		var service = new google.maps.places.PlacesService(container);
		service.getDetails({
				placeId: datum.placeId
			}, function (place, status) {
				if (status === google.maps.places.PlacesServiceStatus.OK) {
					document.getElementById("container").innerHTML = "Lat :" + place.geometry.location.lat() + "<br/>" + "Lan:" + place.geometry.location.lng()
				}
			}
		)
	});


	$maplab
		.maplab({
			map: map,
			typeahead: {
				datasets: [
					bodyShopDataset.createTypeaheadDataset(),
					{
						async: true,
						display: 'address',
						source: function (search, process) {
							service.getPlacePredictions({
								input: search,
								bounds: map.getBounds(),
								types: ["(regions)"],
								sessionToken: sessionToken
							}, function (predictions, status) {
								process(formatAddress(predictions, status));
							});
						},
						templates: {
							header: document.getElementById("typeahead-bingmap-header").innerHTML,
							empty: [
								'<div class="empty-message text-center" style="">',
								'No address available.<br/>',
								'</div>',
							].join('\n'),
							suggestion: function (data) {
								return ['<div data-place-id="' + data.placeId + '">' + data.address + '</div>'].join('\n');
							},
						}
					}
				]
			}
		})
		.on("maplab:initialized", function () {
			var bodyshopData = JSON.parse(document.getElementById("bodyshop-data").innerHTML);
			var bodyShops = $(bodyshopData.bodyshops).filter(function () {
				return this.location && this.location.latitude !== -1 && this.location.longitude !== -1;
			}).get();
			var baseURL = "../../assets/img/marker/";

			$(document).ready(function () {
				$(window).resize(function () {
					google.maps.event.trigger(map, 'resize');
				});
				google.maps.event.trigger(map, 'resize');
			});
			

			for (var i = 0; i < bodyShops.length; i++) {
				var position = new google.maps.LatLng(bodyShops[i].location.latitude, bodyShops[i].location.longitude);

				var marker = new google.maps.Marker({
					position: position,
					title: bodyShops[i].name,
					icon: {
						url: baseURL + getImageNameByColorCode(bodyShops[i].color)
					}
				});

				loc = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
				bounds.extend(loc);

				var markerTemplateStart = "<div class=\"griddle-item griddle-item-map\" {{#if bodyshop.isAccessible}} onclick=\"location.href = \'{{ bodyshop.links.details.href }}\'\"  {{/if}}>";
				var markerTemplateContent = $("#map-marker").html();
				var markerTemplateEnd = "</div>";

				var markerTemplate = markerTemplateContent
					? Handlebars.compile(markerTemplateStart + markerTemplateContent + markerTemplateEnd)
					: null;

				if (markerTemplate) {
					(function (currentMarker, currentMap, currentBodyShopId) {
						jQuery.getJSON(bodyshopData.links.self.href.replace("__id__", currentBodyShopId), function (bodyshop) {

							var infowindow = new google.maps.InfoWindow({
								content: markerTemplate({bodyshop: bodyshop})
							});

							google.maps.event.addListener(currentMarker, 'click', (function (currentMarker, infowindow) {
								return function () {
									if (current) {
										current.close()
									}
									currentMap.panTo(new google.maps.LatLng(currentMarker.position.lat(), currentMarker.position.lng()));
									current = infowindow;
									infowindow.open(currentMap, currentMarker);
								}
							})(currentMarker, infowindow));

							google.maps.event.addListener(map, 'click', (function (infowindow) {
								return function () {
									infowindow.close();
								}
							})(infowindow));
							currentMarker.setMap(currentMap);
						});
					})(marker, map, bodyShops[i].id)
				} else {
					marker.setMap(map);
				}
			}

			var maxZoom = 17;

			google.maps.event.addListener(map, 'zoom_changed', function () {
				zoomChangeBoundsListener =
					google.maps.event.addListener(map, 'bounds_changed', function (event) {
						if (this.getZoom() > maxZoom && this.initialZoom == true) {
							this.setZoom(maxZoom);
							this.initialZoom = false;
						}
						google.maps.event.removeListener(zoomChangeBoundsListener);
					});
			});
			map.initialZoom = true;

			if (bodyShops.length) {
				map.fitBounds(bounds);
				map.panToBounds(bounds);
			}
			/*
			// geolocation.
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(function (position) {
					var pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude
					};
					currentMap.panTo(new google.maps.LatLng(pos.lat, pos.lng));
				});
			}
			 */

		})
		.on("maplab:select", function (event, bs, dataset) {
			var bounds;

			function handleResult(bounds) {
				$("[name='startLatitude']").val(bounds[0]);
				$("[name='startLongitude']").val(bounds[1]);
				$("[name='endLatitude']").val(bounds[2]);
				$("[name='endLongitude']").val(bounds[3]);
				$(".form-search .submit").trigger("click")
			}

			if (bs.placeId) {

				var geocoder = new google.maps.Geocoder;
				geocoder.geocode({"placeId": bs.placeId}, function (results, status) {
					if (status !== 'OK') {
						window.alert('Geocoder failed due to: ' + status);
						return;
					}
					var viewport = results[0].geometry.viewport;
					var southWest = viewport.getSouthWest();
					var northEast = viewport.getNorthEast();
					bounds = [
						southWest.lat(), 
						southWest.lng(), 
						northEast.lat(), 
						northEast.lng()
					];
					handleResult(bounds)
				});
			} else {
				var l = bs.location;
				bounds = [l.latitude, l.longitude, l.latitude, l.longitude];
				handleResult(bounds)
			}
		});
}

jQuery(document).ready(function ($) {
	if ($(".map-legend").length === 0) {
		var map = $(".content-area");
		map.css('display', 'block');
	}
});
