(function($) {
	var FilterPanel = function($panel, options) {
		var panel = this;

		this.panel = $panel;
		this.form = $panel.is("[data-filter-panel-form]") ? $($panel.attr("data-filter-panel-form")) : $panel.closest("form");
		this.submitType = $panel.is("[data-submit-type]") ? $panel.attr("data-submit-type") : "redirect";

		if(panel.submitType === "redirect") {
			// it’s necessary to store the current values of multi-choice selects
			// as we have to determine the newly selected or unselected value
			// when a change event is fired
			$panel.find("select[multiple]").each(function() {
				$(this).data("filter-value", $(this).val());
			});
		}

		$panel
			.on("replace", "select", function() {
				$(this).chosen({
					allow_single_deselect: true,
					width: "100%"
				});
			})
			.on("change", "select", function() {
				var $select = $(this);

				if(panel.submitType === "redirect") {
					var redirectTarget;

					// multi-choice selects need extra logic to retrieve the
					// newly selected or deselected value
					if($select.is("[multiple]")) {
						var currentValue = $select.val() || [];
						var originalValue = $select.data("filter-value") || [];

						// depending on the length of the current value we can
						// determine if a new selection was made or if one was removed
						if(currentValue.length > originalValue.length) {
							redirectTarget = $(currentValue).not(originalValue).get()[0];
						} else {
							redirectTarget = $(originalValue).not(currentValue).get()[0];
						}
					} else {
						redirectTarget = $select.val();
					}

					location.href = redirectTarget;
				} else {
					panel.form.submit();
				}
			})
			.on("blur", "input", function() {
				var $this = $(this);
                        
				if($this.val() !== $this.data("stored-value")) {
                                        panel.form.submit();
				}
			})
                        .on("keydown", function(e) {
                                if(e.keyCode === 13) {
                                        panel.form.submit();
                                }
                        })
			.find("input").each(function() {
				var $this = $(this);
				$this.data("stored-value", $this.val());
			}).end()
			.find(":submit").hide();
	};

	FilterPanel.prototype = {

	};

	(function() {
		$.fn.filterPanel = function(method) {
			if(FilterPanel.prototype.hasOwnProperty(method)) {
				return FilterPanel.prototype[method].apply(this.data("filterPanel"), [].slice.call(arguments, 1));
			} else {
				return this.each(function() {
					var $panel = $(this);
					var options = options || {};

					var panel = new FilterPanel($panel, options);
					$panel.data("filterPanel", panel);

					return $panel;
				});
			}
		};
	})();
})(window.jQuery);