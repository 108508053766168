(function($) {
	$(document).ready(function() {
		if($("#actionPlanReport").length === 0) return;

    var listContainer = $(".list-container, .container-list").listContainer({
		    closeAnimationSpeed: "fast",
		    openAnimationSpeed: "slow",
		    closeAnimation: "slideUp",
        openAnimation: "slideDown",
		    autoOpenOnSelector: ".error, #customer-no:empty"
	  }).data("listContainer");
    
    $(this).docketChart();
    
    $("[data-pony-prototype]").pony();
  });
})(jQuery);