(function(ns) {
	var DocketAnswerChartGenerator = function(data) {
		this.data = data;
		this.defaultHighChartRenderOptions = {
			legend: {
				enabled: true
			},
			credits: {
				enabled: false
			},
			title: {
				text: null
			},
			subtitle: {
				text: null
			},
			xAxis: {
				min: 0,
				max: 60,
				tickInterval: 5,
				title: {
					text: null,
					style: {
						color: "#666666"
					}
				}
			},
			yAxis: {
				min: 0,
				max: 100,
				tickInterval: 10,
				title: {
					text: null,
					style: {
						color: "#666666"
					}
				},
				showLastLabel: false
			},
			plotOptions: {
				series : {
					animation: false,
					enableMouseTracking: false,
					connectNulls : true,
					pointInterval: 5
				}
			},
			series: [],
			tooltip: {
				enabled: false
			}
		};
	};

	DocketAnswerChartGenerator.prototype.generateRenderOptions = function() {
		var options = this.defaultHighChartRenderOptions;

		options.title.text = this.data.title;
		options.xAxis.title.text = this.data.xTitle;
		options.yAxis.title.text = this.data.yTitle;
		
		var series = this.data.series;
		for (var slot in series) {
			var data = series[slot];
			options.series.push({
				name : data.name,
				color : data.color,
				data : data.values
			});
		}

		return options;
	};

	ns.DocketAnswerChartGenerator = DocketAnswerChartGenerator;
})(de.ittecture.chart.generator);