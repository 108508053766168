jQuery(document).ready(function ($) {
	if($("#survey").length === 0) return;

	var finishButton = $("[name='doFinishSurvey']");

	function updateTaskCounters(counterName, count, total) {
		var taskCounterSelector = "[data-taskcounter-reference='" + counterName + "']";

		$(taskCounterSelector + "[data-taskcounter-type='remaining']").html(total - count).css("visibility", count < total ? "visible" : "hidden");
		$(taskCounterSelector + "[data-taskcounter-type='done']").html(count);
	}

	$(".question-set select").broadSelect({
		badgeStyle: "badge badge-large badge-highlight"
	});

	var refreshProgress = function () {
		var $listContainerItem = $(this).closest(".list-container-item, .container-list-item");
		var total = $listContainerItem.find("select").length;
		var count = total - $listContainerItem.find("select option[value='']:selected").length;

		updateTaskCounters($listContainerItem.attr("id"), count, total);
	};

	$(".question-set select").on("change", refreshProgress);

	var listContainer = $(".list-container, .container-list").listContainer({
		closeAnimationSpeed: "fast",
		openAnimationSpeed: "slow",
		closeAnimation: "slideUp",
		openAnimation: "slideDown",
		autoOpenOnSelector: ".error, #customer-no:empty"
	}).data("listContainer");

	$("#survey .page-header .button-toolbar a").on("click", function(event) {
		event.preventDefault();

		var $link = $(this);
		var category = $link.attr("href").substr(1);

		if(!listContainer.isContainerOpened(category)) {
			$("html, body").animate({
				scrollTop: 0
			});
		}

		var navigateToUnsolvedQuestion = function() {
			var $category = listContainer.getContainer(category);

			var $firstUnsolvedQuestion = $category.find(".broad-select:not(.broad-selected)").first();
			var scrollPos = !!$firstUnsolvedQuestion.length ? $firstUnsolvedQuestion.offset().top : $category.offset().top;

			$("html, body").animate({
				scrollTop: Math.max(scrollPos - $.keepInSightHeight(scrollPos), 0)
			});
		}

		listContainer.openContainer(category, navigateToUnsolvedQuestion);
	});

	$(".question-meta").on("click", function() {
		$(this).toggleClass("show-help");
	});

	$("textarea").autosize();
	$(".list-container .list-container-item, .container-list .container-list-item").scrollNav($(".page-header .button-toolbar"));
	$(".question-set tr:first-child select, .interview li:first-child select").each(refreshProgress);

	finishButton.userMessage({
		actions: {
			"yes": function() {
				finishButton.trigger("click");
			}
		}
	});

	window.bpc_exit_button = new FinishSurveyApprovalController(function(isApproved) {
		if(!isApproved)
			finishButton.attr("disabled", "disabled")
			.siblings(".button-note")[isApproved ? "hide" : "show"]();
		else
			finishButton
			.prop("disabled", !isApproved)
			.siblings(".button-note")[isApproved ? "hide" : "show"]();
	});
});

(function($) {
	window.answerSurveyQuestions = function(min, max) {
		min = min ? min : 1;
		max = max ? max : 7;

		$(".broad-select").each(function() {
			var elements = $(this).find(".broad-list li").slice(min, max);
			$(elements[Math.floor(Math.random() * elements.length)]).trigger("click");
		});
	};
})(jQuery);

FinishSurveyApprovalController = function(approvalCallback) {
	var referenceProperty = "data-image-reference";
	var controller = this;

	this.createPropertyMap = function(data, property, converter) {
		var map = {};
		converter = converter || function(element) { return element; };

		$.each(data, function() {
			map[$(this).attr(property)] = converter(this);
		});

		return map;
	};

	this.dataFields = $(".required-bs-data input");
	this.questions = $(".question-set select:not(.donequest), .interview select:not(.donequest)");
	this.ignoreFields = [];
	this.isApproved = false;
	this.approvalCallback = approvalCallback;
	this.photoFields = this.createPropertyMap($(".image-showcase-row input[type='file']"), referenceProperty);
	this.photoRemovalFields = this.createPropertyMap($(".image-showcase-row input[type='checkbox']"), referenceProperty);
	this.photoFieldsReady = this.createPropertyMap(this.photoFields, referenceProperty, function(photoField) {
		return $(photoField).is("[data-set-in-backend='true']");
	});

	this.update = function () {
		controller.questions = $(".question-set select:not(.donequest), .interview select:not(.donequest)");
		var questionCount = controller.questions.length + controller.dataFields.length;
		var answeredQuestions = controller.questions.filter(function () { var val = $(this).val(); return val !== "" && val !== null;}).length + controller.dataFields.filter(function () { return $(this).val() !== "";}).length;
		var allQuestionsAnswered = (questionCount - answeredQuestions) === 0;
		var allPhotoFieldsReady = true;

		for(var photoType in controller.photoFieldsReady) {
			allPhotoFieldsReady &= controller.photoFieldsReady[photoType];
		}

		var isApproved = allQuestionsAnswered && allPhotoFieldsReady;

		if(controller.isApproved !== isApproved) {
			controller.isApproved = isApproved;
			this.approvalCallback(isApproved);
		}
	};

	$.each(this.photoFields, function() {
		$(this).on("change", function() {
			controller.photoFieldsReady[$(this).attr(referenceProperty)] = true;
			controller.update();
		});
	});

	$.each(this.photoRemovalFields, function() {
		$(this).on("change", function() {
			controller.photoFieldsReady[$(this).attr(referenceProperty)] = !$(this).is(":checked");
			controller.update();
		});
	});

	this.dataFields.on("input", function() {
		controller.update();
	});

	this.update();
};
