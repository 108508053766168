jQuery.fn.crunch = function(calculationEngine, callback, defaultIfNaN) {
	defaultIfNaN = defaultIfNaN || 0;
	calculationEngine = typeof calculationEngine === "string" ? jQuery.fn.crunch.engines[calculationEngine] : calculationEngine;

	var $numbers = $(this);
	var apply = function() {
		var values = [];

		$numbers.each(function() {
			var value = parseFloat($(this).val());
			values.push(isNaN(value) ? defaultIfNaN : value);
		});

		callback(calculationEngine(values));
	};

	$numbers.on("change keyup mouseup", apply);
	apply();

	return this;
};

jQuery.fn.crunch.engines = {
	sum: function(summands) {
		var total = 0;

		for(var i = 0, ii = summands.length; i < ii; i++) {
			total += summands[i];
		}

		return total;
	}
};