jQuery(document).ready(function ($) {
	if($("#survey-tablet").length === 0) return;

	var $reopenButton = $("[name='doReopen']");

	$reopenButton.userMessage({
		actions: {
			"yes": function() {
				$reopenButton.trigger("click");
			}
		}
	});
});