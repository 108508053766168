/**
 * This plugin prevents auto-submissions of forms when pressing the return key
 */
(function($) {
	(function() {
		$.fn.formSubmitPrevention = function() {
			this.on("keydown", function(event) {
				if(event.keyCode === 13) {
					event.preventDefault();
				}
			});
		};
	})();
})(jQuery);