(function ($) {
	$(document).ready(function () {
		if ($("#multi-data-usage-requests").length === 0) {
			return;
		}
		var parameter = "dataConsent";
		var czn = document.querySelector(".consent-filter-panel .chzn-results");
		czn.addEventListener("mouseup", function () {
			var select = document.querySelector(".consent-filter-panel select");
			var loc = window.location;
			var oldValue = new URLSearchParams(loc.search).get(parameter);
			var newValue = select.options[select.selectedIndex].value;
			if ((oldValue === null && newValue !== null) || oldValue !== newValue) {
				window.location = loc.pathname + replaceQueryParam(parameter, newValue, loc.search);
			}
		});
	});
})(jQuery);

function replaceQueryParam(param, newval, search) {
	var regex = new RegExp("([?;&])" + param + "[^&;]*[;&]?");
	var query = search.replace(regex, "$1").replace(/&$/, '');

	return (query.length > 2 ? query + "&" : "?") + (newval ? param + "=" + newval : '');
}
