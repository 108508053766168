(function($) {
	// currently only supports selects with multiple attribute
	var SelectExpander = function(select) {
		var root = $("<div class='select-expander'></div>");
		var isDisabled = select.is(":disabled");

		root
			.attr("id", select.attr("id") + "_expanded")
			.attr("class", select.attr("class"))
		;

		select.find("option").each(function() {
			var $option = $(this);
			var $label = $("<label class='select-expander-option' />");
			var $replacement = $("<input type='checkbox' />");

			var source =
				$option.is("[id]") ? ("#" + $option.attr("id"))
					: $option.is("[name]") ? ("[name='" + $option.attr("name") + "']")
					: ("option:nth-child(" + ($option.index() + 1) + ")");

			$replacement
				.prop("disabled", $option.is(":disabled") || isDisabled)
				.prop("checked", $option.is(":selected"))
				.attr("data-name", $option.attr("name"))
				.attr("data-source", source)
			;

			$label
				.append($replacement)
				.append("<span title=\"" + $option.text() + "\">" + $option.text() + "</span>")
			;

			root.append($label);
		});

		root.on("change", "input", function() {
			var values = root
				.find("input:checked")
				.map(function() {
					return select.find($(this).attr("data-source")).val()
				})
				.get();

			select
				.val(values)
				.trigger("change")
			;
		});

		select
			.hide()
			.after(root)
		;

		return root;
	};

	var methods = {
		initialize: function() {
			return this.each(function() {
				var $select = $(this);

				if(!$select.is("select[multiple]")) return null;

				return new SelectExpander($select);
			});
		}
	};

	$.fn.selectExpander = function(method) {
		if (methods[method]) {
			return methods[method].apply(this, [].slice.call(arguments, 1));
		} else {
			return methods.initialize.apply(this, arguments);
		}
	};
})(jQuery);