jQuery.fn.compliance = function() {
	var Compliance = function(element) {
		var self = this;

		self.element = $(element);
		self.grant = $(self.element.find(".compliance-grant input"));

		function updateGrant() {
			if(self.grant.is(":checked")) {
				self.element.addClass("compliance-granted");
			} else if(self.grant.length > 0) {
				self.element.removeClass("compliance-granted");
			}
		}

		self.grant.on("change", updateGrant);

		self.element.on("click", ".compliance-title", function() {
			self.element.toggleClass("compliance-open");
		});

		updateGrant();
	};

	return this.each(function(container) {
		var $container = $(container);
		var compliance = $container.data("compliance");

		if(!compliance) {
			$container.data("compliance", new Compliance(this))
		}

		return this;
	});
};