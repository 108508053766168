/**
 * be aware that the portal component may or may not break event handlers
 * of the src it receives
 *
 *  * delegated events that rely on the specific place of the component
 *    in the dom tree will lose track of the src
 *  * events that are attached to the component itself or one of its
 *    children may not receive any further events once detached
 *
 * please test your specific component!
 */
(function(component_name) {
	rivets.components[component_name] = {
		template: function () {
			return "<div></div>"
		},
		initialize: function (el, data) {
			data.src.detach().appendTo(el);
		}
	}
})("app-portal");
